import { Component, Input, OnInit } from '@angular/core';
import { NgxGaugeType } from 'ngx-gauge/gauge/gauge';
import { ReplaySubject, takeUntil } from 'rxjs';
import { SmartFlow } from 'src/app/classes/smartflow';

@Component({
  selector: 'app-density',
  templateUrl: './density.component.html',
  styleUrls: ['./density.component.css']
})
export class DensityComponent implements OnInit {
  @Input()
  smartflow: SmartFlow = new SmartFlow();
  gaugeType:NgxGaugeType = "full";
  gaugeValue = 28.3;
  gaugeLabel = "Water added";
  gaugeAppendText = "%";

  constructor() {
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  ngOnInit(): void {
    // We need to process the speed more then othere values because 
    // we are controlling the speed guge
    this.smartflow.dc.getWaterSubject().pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.gaugeValue = value;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
