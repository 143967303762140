<div class="card shadow border-start-primary w-100">
    <div [ngClass]="(smartflow.counter.offline || smartflow.counter.data.name === 'null'? 'bg-secondary text-white' : 'text-success')"
        class="card-header text-center">
        <h6 class="fw-bold m-0">{{smartflow.counter.data.name ===
            'null'? "Counter Disconnected": smartflow.counter.data.name}}</h6>
    </div>
    <div class="card-body">
        <section>
            <section class="example-section">
                <mat-progress-bar style="height: 24px;"
                    [ngClass]="(smartflow.counter.data.capacity < 60 ? 'green-progress' : (smartflow.counter.data.capacity < 80 ? 'yellow-progress' : 'red-progress'))"
                    [mode]="mode" [value]="smartflow.counter.data.capacity" [bufferValue]="bufferValue">
                </mat-progress-bar>
            </section>
        </section>
        <mat-divider></mat-divider>
        <section name="CH1">
            <div class="row align-items-center">
                <div class="col text-uppercase text-primary fw-bold text-xs mb-1">
                    <span>{{smartflow.counter.data.channel1.name}}</span>
                </div>
                <div class="col-auto" *ngIf="smartflow.counter.data.channel1.flow !== -1">
                    <span>{{smartflow.counter.data.channel1.flow}} fish/m</span>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col text-dark fw-bold h5 mb-0 text-center"><span
                        style="font-size: 36px;">{{smartflow.counter.data.channel1.count}}</span></div>
            </div>
            <div class="row align-items-center">
                <div class="col"><label class="form-label">{{smartflow.counter.data.channel1.bio}}</label></div>
                <div class="col-auto"><span>{{smartflow.counter.data.channel1.percentage}} %</span></div>
            </div>
        </section>
        <mat-divider *ngIf="smartflow.counter.data.nr > 1"></mat-divider>
        <section name="CH2" *ngIf="smartflow.counter.data.nr > 1">
            <div class="row align-items-center">
                <div class="col text-uppercase text-primary fw-bold text-xs mb-1">
                    <span>{{smartflow.counter.data.channel2.name}}</span>
                </div>
                <div class="col-auto" *ngIf="smartflow.counter.data.channel2.flow !== -1">
                    <span>{{smartflow.counter.data.channel2.flow}} fish/m</span>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col text-dark fw-bold h5 mb-0 text-center"><span
                        style="font-size: 36px;">{{smartflow.counter.data.channel2.count}}</span></div>
            </div>
            <div class="row align-items-center">
                <div class="col"><label class="form-label">{{smartflow.counter.data.channel2.bio}}</label></div>
                <div class="col-auto"><span>{{smartflow.counter.data.channel2.percentage}} %</span></div>
            </div>
        </section>
        <mat-divider *ngIf="smartflow.counter.data.nr > 2"></mat-divider>
        <section name="CH3" *ngIf="smartflow.counter.data.nr > 2">
            <div class="row align-items-center">
                <div class="col text-uppercase text-primary fw-bold text-xs mb-1">
                    <span>{{smartflow.counter.data.channel3.name}}</span>
                </div>
                <div class="col-auto" *ngIf="smartflow.counter.data.channel3.flow !== -1">
                    <span>{{smartflow.counter.data.channel3.flow}} fish/m</span>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col text-dark fw-bold h5 mb-0 text-center"><span
                        style="font-size: 36px;">{{smartflow.counter.data.channel3.count}}</span></div>
            </div>
            <div class="row align-items-center">
                <div class="col"><label class="form-label">{{smartflow.counter.data.channel3.bio}}</label></div>
                <div class="col-auto"><span>{{smartflow.counter.data.channel3.percentage}} %</span></div>
            </div>
        </section>
        <mat-divider *ngIf="smartflow.counter.data.nr > 3"></mat-divider>
        <section name="CH4" *ngIf="smartflow.counter.data.nr > 3">
            <div class="row align-items-center">
                <div class="col text-uppercase text-primary fw-bold text-xs mb-1">
                    <span>{{smartflow.counter.data.channel4.name}}</span>
                </div>
                <div class="col-auto" *ngIf="smartflow.counter.data.channel4.flow !== -1">
                    <span>{{smartflow.counter.data.channel4.flow}} fish/m</span>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col text-dark fw-bold h5 mb-0 text-center"><span
                        style="font-size: 36px;">{{smartflow.counter.data.channel4.count}}</span></div>
            </div>
            <div class="row align-items-center">
                <div class="col"><label class="form-label">{{smartflow.counter.data.channel4.bio}}</label></div>
                <div class="col-auto"><span>{{smartflow.counter.data.channel4.percentage}} %</span></div>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="row align-items-center">
            <div class="col me-4">
                <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Total</span></div>
                <div class="text-dark h5 text-center"><span
                        style="font-size: 36px;">{{smartflow.counter.data.total}}</span></div>
                <div class="col text-dark text-center"><span>{{smartflow.counter.getTotalFlow()}} fish/m</span></div>
            </div>
            <div class="col me-4">
                <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>STATUS</span></div>
                <div class="text-dark h6 text-center"><span style="font-size: 28px;"
                        [ngClass]="(smartflow.counter.data.on ? 'text-success' : 'text-danger')">{{smartflow.counter.data.on?"ON":"OFF"}}</span>
                </div>
            </div>

        </section>
    </div>
</div>