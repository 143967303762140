import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SmartFlowInfo } from '../models/User';
import { FirestoreService } from './firestore/firestore.service';
import { RestApiService } from './rest-api.service';
import { GlobalDataService } from './global-data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn = new BehaviorSubject(false);

  constructor(public auth: AngularFireAuth, public fs: FirestoreService, public restApi: RestApiService, public globalData: GlobalDataService) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // Just connected. Set user
        this.setLogin(user);
        // Get all data that user has
        this.fs.loggedIn(user.uid).then(
          (suc) => { this.loggedIn.next(true) },
          (err) => { this.loggedIn.next(true) }
        );

        this.fs.isAdmin(user.uid).subscribe((data) => {
          // Here we can see if the user is admin
          this.globalData.admin = data !== null && data !== undefined;
        });

      } else {
        // Logout if loged in
        this.globalData.admin = false;
        this.fs.logout();
        this.logout();
        this.loggedIn.next(false);
      }
    });
  }

  setLogin(auth: any) {
    if (auth) {
      // We are logged in
      this.globalData.login.next(true);

      this.fs.getUserData(auth.uid).subscribe((data) => {
        if (data !== undefined) {
          if (!data.hasOwnProperty('user')) {
            // The user data dose not exist lets write it
            this.globalData.user.uid = auth.uid;
            this.globalData.user.displayName = auth.providerData[0]?.displayName!;
            this.globalData.user.email = auth.providerData[0]?.email!;
            this.globalData.user.phoneNumber = auth.providerData[0]?.phoneNumber!;
            this.globalData.user.photoURL = auth.providerData[0]?.photoURL!;
            this.globalData.user.providerId = auth.providerData[0]?.providerId!;
            this.fs.updateUserData(auth.uid, { user: this.globalData.user });
          } else {
            // user data dose exist let grab it
            this.globalData.user = data['user']
          }
        }
      });
    }
  }

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.auth.signInWithEmailAndPassword(email, password).then(
        (userData) => {
          resolve(userData);
        },
        (err) => reject(err)
      );
    });
  }

  register(email: string, password: string, username: string) {
    return new Promise((resolve, reject) => {
      this.auth.createUserWithEmailAndPassword(email, password).then(
        (userData) => {
          if (userData !== null) {
            setTimeout(() => {
              // We wait until everhting has been setup
              this.fs.updateUserData(userData.user!.uid, {
                name: username,
              });
            }, 5000);
          }
          resolve(userData);
        },
        (err) => reject(err)
      );
    });
  }

  getAuth() {
    return this.auth.authState.pipe(map((auth) => auth));
  }

  logout() {
    this.auth.signOut();
    this.globalData.clearUser();
    this.globalData.login.next(false);
  }

  sendPasswordResetEmail(passwordResetEmail: string) {
    return this.auth.sendPasswordResetEmail(passwordResetEmail);
  }

  sendVerificationMail() {
    return this.auth.currentUser.then((u) => u?.sendEmailVerification());
  }

  addSmartFlow(smartflow: SmartFlowInfo): Promise<boolean> {
    return this.fs.addSmartFlowIfExist(this.globalData.user.uid, smartflow);
  }

  updateSmartFlow(smartflowInfo: SmartFlowInfo): Promise<void> {
    return this.fs.updateSmartFlow(this.globalData.user.uid, smartflowInfo);
  }

  removeSmartFlow(doc_id: string, smartlfow_id: string) {
    this.fs.removeSmartFlow(this.globalData.user.uid, doc_id, smartlfow_id);
  }
}