<div class="row content">
    <div class="d-flex justify-content-between">
        <h2 class="mx-4 my-2"><span class="text-primary">{{this.globalData.user.displayName}}</span> Profile</h2>
    </div>
</div>
<div class="top mx-auto p-4" style="max-width: 800px;">
    <mat-accordion class="example-headers-align mb-4 mx-auto" style="max-width: 600px;">
        <mat-expansion-panel [expanded]="true" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h6 class="mt-2">ID : {{this.globalData.user.uid}}</h6>
                </mat-panel-title>
                <mat-panel-description>
                    <p></p>
                    <mat-icon>edit</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form #clientForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <img class="avatar mat-elevation-z8 mx-1" src={{this.globalData.user.photoURL}} *ngIf="!url" />
                            <img class="avatar mat-elevation-z8 mx-1" [src]="url" *ngIf="url" />

                            <mat-form-field class="image_feald">
                                <ngx-mat-file-input name="input_img" [(ngModel)]="this.img_path" placeholder="Set new profile image" (change)="selectFile($event)"></ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                              </mat-form-field>
                        </div>
                        <div class="col">
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">Name</mat-label>
                                    <input matInput name="name" #addedName="ngModel"
                                        value="{{this.globalData.user.displayName}}"
                                        [ngClass]="{'is-invalid':addedName.errors && addedName.touched}"
                                        [(ngModel)]="this.globalData.user.displayName" minlength="2" required>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">Title</mat-label>
                                    <input matInput name="farm" [(ngModel)]="this.globalData.user.job_title">
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">Company</mat-label>
                                    <input matInput 
                                        [(ngModel)]="this.globalData.user.company" name="company">
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">Department</mat-label>
                                    <input matInput name="department" [(ngModel)]="this.globalData.user.department">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">Country</mat-label>
                                    <input matInput 
                                        [(ngModel)]="this.globalData.user.country" name="country">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">City</mat-label>
                                    <input matInput 
                                        [(ngModel)]="this.globalData.user.city" name="city">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <mat-form-field>
                                    <mat-label class="mat-label">Address</mat-label>
                                    <input matInput
                                        [(ngModel)]="this.globalData.user.address" name="address">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <mat-form-field class="w-100">
                            <mat-label class="mat-label">About</mat-label>
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [(ngModel)]="this.globalData.user.about"
                                name="about"></textarea>
                        </mat-form-field>
                    </div>
                    <button type="submit" [disabled]="!(clientForm.valid && clientForm.dirty)" mat-raised-button
                        color="primary" class="w-100">SAVE</button>
                </div>
            </form>
        </mat-expansion-panel>
        <div *ngIf="message" class="alert mt-4" [ngClass]="message_type" role="alert">
            {{errorMessage}}
        </div>
    </mat-accordion>
</div>