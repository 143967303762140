<mat-divider *ngIf="authService.globalData.login.value"></mat-divider>

<button mat-button class="menu-button" [ngClass]="{'active': step === 0}" routerLink="/">
    <mat-icon>dashboard</mat-icon>
    <span>Dashboard</span>
</button>
<button mat-button class="menu-button" [ngClass]="{'active': step === 1}"
    [disabled]="!authService.globalData.login.value" routerLink="/smartflows">
    <mat-icon>device_hub</mat-icon>
    <span>SmartFlows</span>
</button>
<button mat-button class="menu-button" [ngClass]="{'active': step === 2}"
    [disabled]="!authService.globalData.login.value" routerLink="/sessions">
    <mat-icon>library_books</mat-icon>
    <span>Sessions</span>
</button>
<mat-divider></mat-divider>
<button *ngIf="authService.globalData.login.value" mat-button class="menu-button" [ngClass]="{'active': step === 9}"
    routerLink="/profile">
    <mat-icon>manage_accounts</mat-icon>
    <span>Profile</span>
</button>
<div *ngIf="authService.globalData.admin">
    <mat-divider></mat-divider>
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [expanded]="true" class="example-headers-align mat-elevation-z0" >
            <mat-expansion-panel-header class="d-flex justify-content-between my-expansion-panel-header specific-class">
                <mat-panel-title>Admin
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <button mat-button class="menu-button" [ngClass]="{'active': step === 5}"
                [disabled]="!authService.globalData.login.value" routerLink="/admin/users">
                <mat-icon><span class="material-symbols-outlined">
                        group
                    </span></mat-icon>
                <span>Users</span>
            </button>
        
            <button mat-button class="menu-button" [ngClass]="{'active': step === 10}"
                [disabled]="!authService.globalData.login.value" routerLink="/admin/smartflows">
                <mat-icon><span class="material-symbols-outlined">
                        list_alt
                    </span></mat-icon>
                <span>All SmartFlows</span>
            </button>
        
            <button mat-button class="menu-button" [ngClass]="{'active': step === 11}"
                [disabled]="!authService.globalData.login.value" routerLink="/admin/quickbase">
                <mat-icon><span class="material-symbols-outlined">assignment_add</span></mat-icon>
                <span>Quickbase</span>
            </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<mat-divider *ngIf="authService.globalData.admin"></mat-divider>

<button *ngIf="!authService.globalData.login.value" mat-button class="menu-button" [ngClass]="{'active': step === 6}"
    routerLink="/register">
    <mat-icon>how_to_reg</mat-icon>
    <span>Register</span>
</button>

<button *ngIf="!authService.globalData.login.value" mat-button class="menu-button" [ngClass]="{'active': step === 7}"
    routerLink="/login">
    <mat-icon>login</mat-icon>
    <span>Login</span>
</button>

<button *ngIf="authService.globalData.login.value" mat-button class="menu-button" (click)=logout() routerLink="/login">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
</button>

<mat-divider></mat-divider>

<button mat-button class="menu-button" [ngClass]="{'active': step === 8}" routerLink="/help">
    <mat-icon>help</mat-icon>
    <span>Help</span>
</button>