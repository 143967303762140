import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { SmartFlow } from 'src/app/classes/smartflow';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent {
  @Input()
  smartflow: SmartFlow = new SmartFlow();

  color = 'warn';
  mode: ProgressBarMode = 'determinate';
  bufferValue = 100;

}
