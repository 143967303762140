<div class="row content">
  <div class="d-flex justify-content-between">
    <h2 class="mx-4 my-2">SmartFlow <span class="text-primary">{{name}}</span></h2>
    <button mat-mini-fab color="primary" class="mb-3" aria-label="Back" (click)="backClicked()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
</div>
  <div class="container" id="print1">
    <app-session-smartflow class="device-card my-3 mx-auto" [smartflow]=smartflow [id]="id"></app-session-smartflow>
    <app-density *ngIf="smartflow.dc.data.name !== 'null'" class="device-card my-3 mx-auto" [smartflow]="smartflow">
    </app-density>
    <app-pump *ngIf="smartflow.pump.data.name !== 'null'" class="device-card my-3 mx-auto" [smartflow]="smartflow">
    </app-pump>
    <app-grader *ngIf="smartflow.grader.data.name !== 'null'" class="device-card my-3 mx-auto" [smartflow]="smartflow">
    </app-grader>
    <app-counter class="device-card my-3 mx-auto" [smartflow]="smartflow"></app-counter>
  </div>
  <section id="print2">
    <app-counter-chart id="print-section" [smartflow]="smartflow"></app-counter-chart>
    <app-grader-chart *ngIf="smartflow.grader.data.name !== 'null'" [smartflow]="smartflow"></app-grader-chart>
    <app-pump-chart *ngIf="smartflow.pump.data.name !== 'null'" [smartflow]="smartflow"></app-pump-chart>
    <app-dc-chart *ngIf="smartflow.dc.data.name !== 'null'" [smartflow]="smartflow"></app-dc-chart>
  </section>
<div class="d-flex justify-content-center">
  <button class="btn btn-outline-secondary col-4 m-4" (click)="openPDF()">
    Print</button>
    <button class="btn btn-outline-secondary col-4 m-4" (click)="downloadCSV()">
      Downloading Raw Data</button>
</div>