<div class="card shadow border-start-primary col-12 col-sm-12 col-md-12 col-xl-12">
    <img class="card-img-top p-2" src="assets/img/sf-all.png">
    <div class="card-body">
        <h6 class="text-center pb-4 pt-1">
            <p><span class="text-primary">Session : {{id}}</span></p>
            <p><span class="text-primary">{{formatTime(smartflow.smartflow.data.online)}}</span></p>
        </h6>
        <form #clientForm="ngForm" name="myForm">
            <section>
                <section class="row" [ngClass]="(smartflow.smartflow.data.running? 'text-success' : 'text-secondary')">
                    <div class="col me-4">
                        <div *ngIf="smartflow.smartflow.data.sp1 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.counter.data.channel1.name}}</span>
                        </div>
                        <div *ngIf="smartflow.smartflow.data.sp2 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.counter.data.channel2.name}}</span>
                        </div>
                        <div *ngIf="smartflow.smartflow.data.sp3 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.counter.data.channel3.name}}</span>
                        </div>
                        <div *ngIf="smartflow.smartflow.data.sp4 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.counter.data.channel4.name}}</span>
                        </div>
                    </div>
                    <div class="col me-4">
                        <div *ngIf="smartflow.smartflow.data.sp1 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.smartflow.data.sp1}}</span>
                        </div>
                        <div *ngIf="smartflow.smartflow.data.sp2 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.smartflow.data.sp2}}</span>
                        </div>
                        <div *ngIf="smartflow.smartflow.data.sp3 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.smartflow.data.sp3}}</span>
                        </div>
                        <div *ngIf="smartflow.smartflow.data.sp4 !== 'null'"
                            class="text-uppercase text-xs mb-1 text-center">
                            <span>{{smartflow.smartflow.data.sp4}}</span>
                        </div>
                    </div>
                    <h6 *ngIf="smartflow.smartflow.data.runTime !== 'null'" class="mt-3 text-center">Runtime:
                        {{smartflow.smartflow.data.runTime}}</h6>
                </section>
                <mat-divider></mat-divider>
            </section>
        </form>
    </div>
</div>