import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxGaugeModule } from 'ngx-gauge';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocatorService } from './services/locator.service';
import { ClientComponent } from './component/client/client.component';
import { NavItemsComponent } from './component/nav-items/nav-items.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ConnectComponent } from './component/dashboard/devices/connect/connect.component';
import { CounterComponent } from './component/dashboard/devices/counter/counter.component';
import { GraderComponent } from './component/dashboard/devices/grader/grader.component';
import { PumpComponent } from './component/dashboard/devices/pump/pump.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { NgChartsModule } from 'ng2-charts';
import { CounterChartComponent } from './component/chart/counter-chart/counter-chart.component';
import { GraderChartComponent } from './component/chart/grader-chart/grader-chart.component';
import { PumpChartComponent } from './component/chart/pump-chart/pump-chart.component';
import { AuthComponent } from './component/auth/auth.component';
import { DeviceComponent } from './component/smartflows/smartflow/device/device.component';
import { SmartflowComponent } from './component/smartflows/smartflow/smartflow.component';

import { DialogSessionDelete } from './component/sessions/sessions.component';
import { DialogDeviceDelete } from './component/smartflows/smartflow/smartflow.component';
import { SmartFlowDeletDialog, SmartflowsComponent } from './component/smartflows/smartflows.component';
import { SessionsComponent } from './component/sessions/sessions.component';
import { SessionComponent } from './component/sessions/session/session.component';
import { SessionSmartflowComponent } from './component/sessions/session/session-smartflow/session-smartflow.component';
import { HelpComponent } from './component/help/help.component';
import { UsersComponent } from './component/users/users.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ProfileComponent } from './component/profile/profile.component';
import { AllSmartflowsComponent, AdminSmartFlowDeletDialog } from './component/admin/all-smartflows/all-smartflows.component';
import { FooterComponent } from './component/footer/footer.component';
import { DcChartComponent } from './component/chart/dc-chart/dc-chart.component';
import { DensityComponent } from './component/dashboard/devices/density/density.component';
import { QuickbaseComponent } from './component/admin/quickbase/quickbase.component';
import { ListComponent } from './component/util/list/list.component';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'smartflow.is',
    expiryDays: 364,
    name: 'law_info_viewed_cookie'
  },
  position: 'bottom',
  palette: {
    popup: {
      background: '#ffffff',
      text: '#1f2122',
      link: '#1f2122'
    },
    button: {
      background: '#002850',
      text: '#ffffff',
      border: 'transparent'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  dismissOnWindowClick: true,
  content: {
    message: 'We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.',
    dismiss: 'Got it!',
    deny: 'Deny cookies',
    link: 'Learn more',
    href: '/cookies',
    policy: 'Policy'
  }
};


@NgModule({
  declarations: [
    AppComponent,
    ClientComponent,
    NavItemsComponent,
    DashboardComponent,
    ConnectComponent,
    CounterComponent,
    GraderComponent,
    PumpComponent,
    NotFoundComponent,
    CounterChartComponent,
    GraderChartComponent,
    PumpChartComponent,
    AuthComponent,
    DeviceComponent,
    SmartflowComponent,
    DialogDeviceDelete,
    DialogSessionDelete,
    SmartflowsComponent,
    SmartFlowDeletDialog,
    SessionsComponent,
    SessionComponent,
    SessionSmartflowComponent,
    HelpComponent,
    UsersComponent,
    ProfileComponent,
    AllSmartflowsComponent,
    AdminSmartFlowDeletDialog,
    FooterComponent,
    DcChartComponent,
    DensityComponent,
    QuickbaseComponent,
    ListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,

    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatGridListModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MaterialFileInputModule,

    FormsModule,
    ReactiveFormsModule,

    NgxGaugeModule,
    NgChartsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),

  ],
  providers: [ScreenTrackingService, 
    UserTrackingService, 
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    LocatorService.injector = injector;
  }
}
