export const environment = {
  production: true,
  version: 'V2.16.5',
  firebase: {
    apiKey: "AIzaSyA3oZmGNlB_jwfZW_4i0ksqFbF-Eonlpxo",
    authDomain: "vaki-smartflow.firebaseapp.com",
    databaseURL: "https://vaki-smartflow.firebaseio.com",
    projectId: "vaki-smartflow",
    storageBucket: "vaki-smartflow.appspot.com",
    messagingSenderId: "507253355168",
    appId: "1:507253355168:web:8e195a0291459678a04fc5",
    measurementId: "G-4HGS76C60H"
  }
};
