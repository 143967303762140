<div class="row content">
    <div class="d-flex justify-content-between">
        <h2 class="mx-4 my-2">SmartFlow <span class="text-primary">{{this.name}}</span></h2>
        <button mat-mini-fab color="primary" class="mb-3" aria-label="Back" (click)="backClicked()">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
</div>
<div class="p-4">
    <div *ngIf="devices.length > 0" class="card m-4 mx-auto" style="max-width: 600px;">
        <img class="card-img-top mx-auto" style="max-width: 300px;" src="{{getImage(getDevice().type)}}"
            alt="Card image cap">
        <div class="card-body">
            <h5 class="card-title">{{getDevice().id}}</h5>
            <div *ngIf="getDevice().type === 'Counter'">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><span class="text-secondary"> Version : {{$any(getDevice()).version}}</span></td>
                            <td><span class="text-secondary"> Teamviwer : {{$any(getDevice()).teamviwer}}</span></td>
                        </tr>
                        <tr>
                            <td><span class="text-secondary"> Channles : {{$any(getDevice()).nr_ch}}</span></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><span class="text-secondary"> Created : {{getTime(getDevice().created)}}</span></td>
                            <td><span class="text-secondary"> Login : {{getTime(getDevice().update)}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="getDevice().type === 'Pump'">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><span class="text-secondary"> Size : {{$any(getDevice())['size']}}&rdquo;</span></td>
                            <td></td>
                            <td><span class="text-secondary"> Speed average : {{$any(getDevice())["Speed average"]}}
                                    %</span></td>
                        </tr>
                        <tr>
                            <td><span class="text-secondary"> Main runtime : {{$any(getDevice())['Main runtime']}}
                                    H</span>
                            </td>
                            <td></td>
                            <td><span class="text-secondary"> Primer runtime : {{$any(getDevice())['Primer runtime']}}
                                    H</span></td>
                        </tr>
                        <tr *ngIf="$any(getDevice())['size'] > 6">
                            <td>
                                <span class="text-secondary">
                                    Thermal state average :{{$any(getDevice())["Thermal state average"]}}% </span>
                            </td>
                            <td>
                                <span class="text-secondary">
                                    Main current average :{{$any(getDevice())["Main current average"]}} A</span>
                            </td>
                            <td>
                                <span class="text-secondary">
                                    Primer current average :{{$any(getDevice())["Primer current average"]}} A</span>
                            </td>
                        </tr>
                        <tr>
                            <td><span class="text-secondary"> Created : {{getTime(getDevice().created)}}</span></td>
                            <td></td>
                            <td><span class="text-secondary"> Login : {{getTime(getDevice().update)}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="getDevice().type === 'SmartGrader'">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <span class="text-secondary"> Runtime : {{$any(getDevice())['rt']}} H</span>
                            </td>
                            <td>
                                <span class="text-secondary"> Angle : {{($any(getDevice())["Angle"]*0.00249 +
                                    1.2).toFixed(1)}}
                                    &#176;</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="text-secondary"> Speed average : {{$any(getDevice())['Speed average']*6 +
                                    12}}
                                    rpm</span>
                            </td>
                            <td>
                                <span class="text-secondary"> Current average : {{$any(getDevice())["Current average"]}}
                                    A</span>
                            </td>
                        </tr>
                        <tr>
                            <td><span class="text-secondary"> Created : {{getTime(getDevice().created)}}</span></td>
                            <td><span class="text-secondary"> Login : {{getTime(getDevice().update)}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="device.type === 'DC'">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><span class="text-secondary"> Created : {{getTime(getDevice().created)}}</span></td>
                            <td><span class="text-secondary"> Login : {{getTime(getDevice().update)}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="auth.globalData.admin"  class="mx-auto" style="max-width: 600px;">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Note
                    </mat-panel-title>
                    <mat-panel-description>
                        Tap here to add!
                        <mat-icon>add_box</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <form #noteForm="ngForm" (ngSubmit)="onSubmit(noteForm)">
                    <div class="form-group" align="center">
                        <mat-form-field appearance="fill" class="mat-one">
                            <mat-label>Title</mat-label>
                            <input matInput name="title" #title="ngModel"
                                [ngClass]="{'is-invalid':title.errors && title.touched}" [(ngModel)]="this.note.title"
                                minlength="2" required>
                        </mat-form-field>
                    </div>
                    <div class="form-group" align="center">
                        <mat-form-field appearance="fill" class="mat-one">
                            <mat-label>Message</mat-label>
                            <textarea matInput name="message" #message="ngModel"
                                [ngClass]="{'is-invalid':message.errors && message.touched}"
                                [(ngModel)]="this.note.message" minlength="2" required></textarea>
                        </mat-form-field>
                    </div>
                    <div class="form-group" align="center">
                        <button type="submit" [disabled]="!(noteForm.valid && noteForm.dirty)" mat-raised-button
                            color="primary" class="mat-one">SAVE</button>
                    </div>
                </form>
                <div *ngIf="message" class="alert m-4" [ngClass]="message_type" role="alert">
                    {{errorMessage}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="mx-auto" style="max-width: 600px;">
        <mat-card *ngFor="let n of notes; index as i" class="mt-4">
            <mat-card-subtitle>
                <div class="d-flex justify-content-between">
                    <p>{{n.data.create}}</p>
                    <p>{{n.data.creator}}</p>
                </div>
            </mat-card-subtitle>
            <mat-card-title><h5>{{n.data.title}}</h5></mat-card-title>
            <mat-card-content>
                <p>{{n.data.message}}</p>
            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions *ngIf="auth.globalData.admin" >
                <button mat-stroked-button color="warn" (click)="delete(n.id)">Delete</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>