import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})

export class SettingsService {

  update = new BehaviorSubject(false);

  connect = {
    id:'',
    name:'',
    connect:false
  }

  settings = {
    updateTime: 15,
    maxPoints: 30, // Minuts
    counter_scale: 100,
    light: false,
    smartflowsList: {
      on:false,
      online:false
    },
    allSmartFlows: {
      on:false,
      online:false,
      graders:false,
      pumps:false
    }
  };

  constructor() { 
  }

  notifyUpdate(b:boolean) {
    this.update.next(b);
  }

  updateMaxScale(n:number) {
      this.settings.counter_scale = n;
  }

  setUpdateTime(n:number) {
    if(n > 0) {
      this.settings.maxPoints = n;
    }
  }

}