import { Helper } from '../util/hleper';
import { DataCollector } from './data-collector';
import { Point } from './point';

interface Grader {
  angle: number;
  auto: boolean;
  large: number;
  medium: number;
  name: string;
  on: boolean;
  runtime: number;
  small: number;
  speed: number;
}

export class GraderClass extends DataCollector  {
  public setAll(): void {
  } 
  public gatDataPoint(load:boolean): Point {
    return new Point([this.data.small, this.data.medium, this.data.large]);
  }
  public init(): void {
  }

  data: Grader = {
    angle: 0,
    auto: false,
    large: 0,
    medium: 0,
    name: 'null',
    on: false,
    runtime: 0,
    small: 0,
    speed: 0,
  };

  defult: Grader = {
    angle: 0,
    auto: false,
    large: 0,
    medium: 0,
    name: 'null',
    on: false,
    runtime: 0,
    small: 0,
    speed: 0,
  };

  reset() {
    Helper.copy(this.defult, this.data);
    // Here we add a 0 data point with true to clear. 
    // Meaing it clear the graph
    this.subject.next(new Point([0, 0, 0], true));
    this.dataClear();
    this.stopCollection();
  }

  clear() {
    this.subject.next(new Point([0, 0, 0], true));
    this.dataClear();
  }
  
}
