import { Component } from '@angular/core';
import { MyMessage } from 'src/app/classes/my-message';
import { SmartFlow } from 'src/app/classes/smartflow';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends MyMessage {
  smartflow: SmartFlow = new SmartFlow();

  constructor(private sf: FirebaseService) {
    super();
    this.smartflow = sf.getSmartFlowClass();
  }

  onSubmit() {
    console.log("Update settings");
  }
}
