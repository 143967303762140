import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(private fns: AngularFireFunctions, private https: HttpClient) {
  }

  // We are goint to stop useing http for this and we are going to use onCall that is app function
  getSmartFlowOwner(sf: string) {
    return this.fns.httpsCallable('getUserDevices')({ sf: sf });
  }

  // We are goint to stop useing http for this and we are going to use onCall that is app function
  getQucikBaseCustomerServices(customer: string) {
    return this.fns.httpsCallable('getQucikBaseCustomerServices')({ customer: customer });
  }

  // We are goint to stop useing http for this and we are going to use onCall that is app function
  getAllQuickbaseUsersOnCall(id: string) {
    return this.fns.httpsCallable('getAllQuickBaseUsersOnCall')({ id: id });
  }

  getAllQuickbaseUsers(customer: string) {
    return this.fns.httpsCallable('getQucikBaseDevices')({ customer: customer });
  }
}
