import { Component, OnInit } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { MyMessage } from 'src/app/classes/my-message';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends MyMessage implements OnInit {
  destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  users:any[] = [];

  constructor(public fs: FirestoreService) {
    super();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.fs
      .getUsers()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        if (data !== null && data !== undefined) {
          this.showMessage('success', 'Download complete.', 3000);
          data.sort((a, b) => a.data.name < b.data.name ? -1 : 1);
          this.users = data.filter(u => {
            return u.data.smartflows !== undefined && u.data.smartflows.length !== 0;
          })
        }
      });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}

interface user {
  access_level:number,
  email: string,
  name: string,
  id: string,
  smartflows:[]
}
