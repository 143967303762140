<div class="container">
  <app-connect [smartflow]="smartflow" class="device-card my-3 mx-auto"></app-connect>

  <app-density *ngIf="(smartflow.dc.data.name !== 'null') || smartflow.dc.data.name !== 'null'"
    class="device-card my-3 mx-auto" [smartflow]="smartflow"></app-density>
 
    <app-pump
    *ngIf="(smartflow.pump.data.name === 'null' && !smartflow.connected.value) || smartflow.pump.data.name !== 'null'"
    class="device-card my-3 mx-auto" [smartflow]="smartflow"></app-pump>
 
    <app-grader
    *ngIf="(smartflow.grader.data.name === 'null' && !smartflow.connected.value) || smartflow.grader.data.name !== 'null'"
    class="device-card my-3 mx-auto" [smartflow]="smartflow"></app-grader>

  <app-counter class="device-card my-3 mx-auto" [smartflow]="smartflow"></app-counter>
</div>
<section>
  <app-counter-chart [smartflow]="smartflow"></app-counter-chart>

  <app-grader-chart
    *ngIf="(smartflow.grader.data.name === 'null' && !smartflow.connected.value) || smartflow.grader.data.name !== 'null'"
    [smartflow]="smartflow"></app-grader-chart>

  <app-pump-chart
    *ngIf="(smartflow.pump.data.name === 'null' && !smartflow.connected.value) || smartflow.pump.data.name !== 'null'"
    [smartflow]="smartflow"></app-pump-chart>

  <app-dc-chart
    *ngIf="(smartflow.dc.data.name === 'null' && !smartflow.connected.value) || smartflow.dc.data.name !== 'null'"
    [smartflow]="smartflow"></app-dc-chart>

  <div class="d-flex justify-content-center">

    <button class="btn btn-outline-secondary col-4 m-4" (click)=smartflow.clear()>Clear Graphs</button>
  </div>
</section>