import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SmartFlow} from 'src/app/models/User';
import { User } from '../models/User';
import { MyArray } from '../util/hleper';
import { QBCustomer } from '../util/quickbase/customer';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  public login = new BehaviorSubject(false);
  // SmartFlows the user has added
  public usersSmartFlows: MyArray<SmartFlow> = new MyArray();
  // All SmartFlows if admin then avilable
  public allSmartFlows: MyArray<SmartFlow> = new MyArray();
  // All SmartFlows if admin then avilable
  customers: Array<QBCustomer> = new Array();
  
  session = {
    selected: { id: '', name: '' },
    loaded: false
  }
  sessions: any[] = [];

  admin: boolean = false;
  user: User = {
    uid: '',
    displayName: '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    providerId: '',
    company: '',
    job_title: '',
    city: '',
    address: '',
    country: '',
    about: '',
    department: ''
  };

  isLogin(): BehaviorSubject<boolean> {
    return this.login;
  }

  clearUser() {
    this.user = {
      uid: '',
      displayName: '',
      email: '',
      phoneNumber: '',
      photoURL: '',
      providerId: '',
      company: '',
      job_title: '',
      city: '',
      address: '',
      country: '',
      about: '',
      department: ''
    };
  }

  constructor() { }
}
