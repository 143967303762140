import { BehaviorSubject } from 'rxjs';
import { TimeStamp } from '../models/Devices';
import { SmartFlow, SmartFlowInfo } from '../models/User';

export class Helper {

  static image: any = {
    Counter: 'assets/img/exel.png',
    Micro: 'assets/img/micro.png',
    Macro: 'assets/img/macro.png',
    Pump: 'assets/img/8pump.png',
    Pump6: 'assets/img/6pump.png',
    Pump8: 'assets/img/8pump.png',
    Pump10: 'assets/img/10pump.png',
    SmartGrader: 'assets/img/grader.png',
    SmartGrader5: 'assets/img/grader5.png',
    DC: 'assets/img/DC.png',
    Cover:
      'https://vakiiceland.is/wp-content/uploads/2020/12/VAKI_Video-fyrir-vef2.gif',
  };

  static onlineTimeDiff = 15;

  static generateTimeStamp = (): TimeStamp => {
    const now = new Date();
    const seconds = Math.floor(now.getTime() / 1000);
    const nanoseconds = (now.getTime() % 1000) * 1000000;

    return { seconds, nanoseconds };
  }


  static copy(source: { [x: string]: any }, target: { [x: string]: any }) {
    for (var key in source) {
      if (typeof source[key] === 'object') {
        Helper.copy(source[key], target[key]);
      } else {
        target[key] = source[key];
      }
    }
  }

  // Need to rethink this.
  static isOnline(time: string): boolean {
    if (time === null || time === undefined) {
      return false;
    }

    let was = Helper.trimTime(time);
    let now = Helper.getTimeStamp();

    was = was.substring(0, 12);
    now = now.substring(0, 12);

    let newWas = was.substring(8, 10);
    let newNow = now.substring(8, 10);

    let was_no_hh = +was - +newWas * 100;
    let now_no_hh = +now - +newNow * 100;

    if (Math.abs(now_no_hh - was_no_hh) > Helper.onlineTimeDiff) {
      return false;
    } else {
      if (Math.abs(now_no_hh - was_no_hh) > Helper.onlineTimeDiff / 3) {
        return false;
      } else {
        return true;
      }
    }
  }

  // Will Combin with isOnline later.
  // Need to rethink this a little.
  static getTimeCSS(time: string): string {
    if (time === null || time === undefined) {
      return 'text-secondary';
    }
    let was = Helper.trimTime(time);
    let now = Helper.getTimeStamp();

    was = was.substring(0, 12);
    now = now.substring(0, 12);

    let newWas = was.substring(8, 10);
    let newNow = now.substring(8, 10);

    let was_no_hh = +was - +newWas * 100;
    let now_no_hh = +now - +newNow * 100;

    if (Math.abs(now_no_hh - was_no_hh) > Helper.onlineTimeDiff) {
      return 'text-danger';
    } else {
      if (Math.abs(now_no_hh - was_no_hh) > Helper.onlineTimeDiff / 3) {
        return 'text-warning';
      } else {
        return 'text-success';
      }
    }
  }

  // is this time string old? 
  static isOffline(time: string): boolean {
    if (time === null || time === undefined) {
      return false;
    }
    let was = Helper.trimTime(time);
    let now = Helper.getTimeStamp();

    was = was.substring(0, 12);
    now = now.substring(0, 12);

    let newWas = was.substring(8, 10);
    let newNow = now.substring(8, 10);

    let was_no_hh = +was - +newWas * 100;
    let now_no_hh = +now - +newNow * 100;

    if (Math.abs(now_no_hh - was_no_hh) > Helper.onlineTimeDiff) {
      return true;
    } else {
      if (Math.abs(now_no_hh - was_no_hh) > Helper.onlineTimeDiff / 3) {
        return false;
      } else {
        return false;
      }
    }
  }

  static createSmartFlowWidthInfo(info: SmartFlowInfo): SmartFlow {
    return {
      created: '',
      session_count: 0,
      session_end: '',
      session_id: '',
      session_on: false,
      session_start: '',
      update: '',
      updateTime: '',
      version: '',
      info: info,
      devices: [],
      count: 0,
      hide: false,
    };
  }

  static getOnlyTime(date: TimeStamp) {
    let time = new Date(date.seconds * 1000).toTimeString();
    if (time.length > 8) {
      return time.substring(0, 8);
    }
    return time;
  }

  static getTime(time: TimeStamp) {
    if (time === undefined) {
      return '';
    }
    return new Date(time.seconds * 1000).toDateString();
  }

  static getTimeStr(time: string) {
    let obj = JSON.parse(time);
    if (time === undefined) {
      return '';
    }
    return new Date(obj.seconds * 1000).toDateString();
  }

  static getEmptySmartFlowInfo(): SmartFlowInfo {
    return {
      id: '',
      notify: false,
      name: '',
      company: '',
      site: '',
      location: '',
      phoneNumber: '',
      docID: '',
      room: '',
      farm: '',
      city: '',
      country: '',
      address: '',
      description: '',
    };
  }

  static getSmartFlowInfoWithNameAndID(
    id: string,
    name: string
  ): SmartFlowInfo {
    return {
      id: id,
      notify: false,
      name: name,
      company: '',
      site: '',
      location: '',
      phoneNumber: '',
      docID: '',
      room: '',
      farm: '',
      city: '',
      country: '',
      address: '',
      description: '',
    };
  }

  static copyInvert(
    source: { [x: string]: any },
    target: { [x: string]: any }
  ) {
    for (var key in target) {
      if (typeof target[key] === 'object') {
        Helper.copy(source[key], target[key]);
      } else {
        source[key] = target[key];
      }
    }
  }

  static trimTime(time: string) {
    return time.replace(/[^\d]/g, '');
  }

  static getTimeStamp() {
    let today = new Date();
    let year = today.getFullYear();
    const arr = [
      today.getMonth() + 1,
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
    ];
    let timeStamp = '' + year;

    arr.forEach((v) => {
      if (v < 10) {
        timeStamp = timeStamp + '0';
      }
      timeStamp = timeStamp + '' + v;
    });

    return timeStamp;
  }
}

export class MyArray<T> {
  private _myArray = new BehaviorSubject<T[]>([]);
  readonly myAray$ = this._myArray.asObservable();
  private myArray: T[] = [];

  size() {
    let a = this.myArray.length
    return a;
  }

  clear() {
    this.myArray = [];
    this._myArray.next(this.myArray);
  }

  push(item: T) {
    this.myArray.push(item);
  }

  notify() {
    this._myArray.next(Object.assign([], this.myArray));
  }

  pop() {
    this.myArray.pop();
  }

  all() {
    return this.myArray;
  }

  get(i: number): T {
    return this.myArray[i];
  }

  getSubject(): BehaviorSubject<T[]> {
    return this._myArray;
  }
}