import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { LocatorService } from '../services/locator.service';
import { SettingsService } from '../services/settings.service';
import { Point } from './point';
import { TimeStamp } from '../models/Devices';

// This class is used as an extention to a model class!
// It will collect data which the parant class will provide, then subject will be updated 
// so anyone listening/subscribe will be noted. 
// We use this for lets say counter, it will be assed to give data points with getDataPoint
// it will give all data points/ the fish/min and subject will be pinged with the data point,
// The chart model can then listen for the subjcet and get the point and add to chart!
// This class is also used to sinc charts update time and collect data while main view or 
// dashboard is not on
export abstract class DataCollector {

  public offline: boolean = true; // We use this for if it connects and then drops offline
  public connected: boolean = false; // Keep track if it connects once
  
  public dataPints: Point[] = [];
  public point: Point = new Point([], false, '');
  public subject = new BehaviorSubject(this.point);
  lastUpdateTime: TimeStamp;

  // Here we get the sevices we wont from the LocatorService injector
  // which app.module gave to LocatorService.
  public settings: SettingsService = LocatorService.injector.get(SettingsService);

  // This numbers will be read from global settings later on
  public updateTime = this.settings.settings.updateTime;
  public maxPoints = 15 * 8 * 60;
  public intervallTimer = interval(this.updateTime * 1000);
  public subscription: Subscription | undefined;

  public abstract setAll(): void;
  public abstract gatDataPoint(load: boolean): Point;
  public abstract init(): void;

  constructor() {
    this.setAll();
  }

  // This will trigger an interval so before the class is distoyed use stopCollecting()
  public startCollecting() {
    this.stopCollection();

    // We wait until the dom is loaded
    setTimeout(() => {
      this.init();
    }, 3000);

    // Collect data every updateTime sek
    this.subscription = this.intervallTimer.subscribe(() => {
      this.subject.next(this.addDataPoint());
    });
  }

  public addDataPoint() {
    let point: Point = this.gatDataPoint(false); // Get the data point from the parent class
    if (this.dataPints.length >= this.maxPoints) {
      this.dataPints.shift();
    }

    const returnPoint = new Point(point.ch);
    this.dataPints.push(returnPoint);
    return returnPoint;
  }

  // Create a data point with label.
  // Only used for adding from db
  public addDataPointWithLable(time: TimeStamp) {
    // Fist get it from parent and add to collection
    let point: Point = this.gatDataPoint(true);
    if (this.dataPints.length >= this.maxPoints) {
      this.dataPints.shift();
    }

    const returnPoint = new Point(point.ch);
    returnPoint.addTime(time);

    this.dataPints.push(returnPoint); // add to collection

    return returnPoint;
  }

  public getDataPoints() {
    return this.dataPints;
  }

  public stopCollection() {
    if (this.subscription !== null && this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  public getSubject() {
    return this.subject;
  }

  public dataClear() {
    this.dataPints = [];
  }
}
