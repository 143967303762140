<div class="d-flex justify-content-between mx-4 mt-2">
    <h2 class="mx-4 my-2">Sessions <span *ngIf="this.global_data.session.loaded"> from <span
                class="text-primary">{{this.global_data.session.selected.name}}</span> downloaded</span> </h2>
</div>
<div class="searchcontainer d-flex justify-content-center mt-2">
    <mat-form-field appearance="fill">
        <mat-label>Select a SmartFlow</mat-label>
        <mat-select disableRipple (selectionChange)="changeClient($event.value)"
            (value)="this.global_data.session.selected">
            <mat-option *ngFor="let smartflow of global_data.usersSmartFlows.all(); index as i"
                [value]="{id: smartflow.info.id, name: smartflow.info.name}">
                <span class="text-secondary">{{smartflow.info.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-stroked-button color="primary" class="my-button" (click)="getSessions()"
        [disabled]="this.global_data.session.selected.id.length == 0" [class.spinner]="downloading">{{downloading?
        '':'GET'}}</button>
</div>

<div *ngIf="message" class="alert mb-1 mx-2" [ngClass]="message_type" role="alert">
    {{errorMessage}}
</div>
 
<div *ngIf="deleting" class="container">
    <div  class="d-flex justify-content-center">
        <div class="row spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div>
    </div>
    <div class="row justify-content-md-center">
        Deleting data, this could take few seconds.
    </div>
</div>

<div class="top mx-auto pt-2" style="max-width: 800px;">
    <div class="form-inline d-flex justify-content-center col-12">
        <button (click)="last()" class="btn btn-outline-secondary mx-2" [disabled]="step == 0">Last</button>
        <div class="form-inline d-flex align-items-center mx-3">
            <h6>Showing {{step * step_size + 1}} - {{(step + 1) * step_size}}  of  {{global_data.sessions.length}} </h6>
        </div>
        <button (click)="next()" class="btn btn-outline-secondary mx-2" [disabled]="((step + 1)  * step_size) >= global_data.sessions.length">Next</button>
    </div>
</div>


<div class="row content mx-auto" style="max-width: 800px;">
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel class="example-headers-align" 
        *ngFor="let session of global_data.sessions | slice:(step * step_size ):((step + 1) * step_size) ; index as i;">
            <mat-expansion-panel-header class="d-flex justify-content-between">
                <mat-panel-title>
                    <small>#{{step * step_size + (i + 1)}} : {{getDataStr(session.data.end)}}</small>
                </mat-panel-title>
                <mat-panel-description>
                    <h6>{{session.data.total.toLocaleString()}} <small>total</small></h6>
                    <p class="mb-1">{{duration(session.data.end, session.data.start)}}</p>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="d-flex mb-3 justify-content-between">
                <p><span class="text-secondary"> Started : {{getTimeStr(session.data.start)}}</span></p>
                <p><span class="text-secondary"> Ended : {{getTimeStr(session.data.end)}}</span></p>
            </div>
            <div class="d-flex justify-content-between">
                <button class="btn btn-outline-primary" [routerLink]="['/sessions/session']"
                    [queryParams]="{ id: session.id, name: this.global_data.session.selected.name}">View</button>
                <button class="btn btn-outline-danger" *ngIf="global_data.admin"
                    (click)="openDialog(session)">Remove</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>