<footer class="bg-light footer">
    <footer class="footer-dark">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-md-3 item">
                    <ul>
                        <h3>SmartFlow Web App <small>{{version}}</small></h3>
                        <li><a href="https://vakiiceland.is/">vaki.is</a></li>
                        <li><a href="https://vakiiceland.is/contact-sales/">Sales</a></li>
                        <li><a href="https://www.msdprivacy.com/no/en/">Privacy Policies</a></li>
                        <li><a href="https://www.msd.com/terms-of-use/">Disclaimer</a></li>
                        <li><a href="https://www.essentialaccessibility.com:443/msd">
                        <li><a href="https://www.msd.com:443/our-commitment-to-accessibility-for-all"
                                className="accessibility-link ">Accessibility</a></li>
                        <img className="accessibility-img "
                            src="https://assets.msd-animal-health.com:443/brands/accessibility/img/accessibility-WhiteOnGray.png"
                            alt="Accessibility button" /></a></li>

                    </ul>
                </div>
                <div class="col-md-6 item text">
                    <h3>VAKI AQUACULTURE SYSTEMS LTD.</h3>
                    <p>VAKI is one of MSD Animal Health (MSD) fish and aquaculture company.
                        MSD is the world’s leading animal intelligence group, providing world-class devices for animal
                        identification and traceability. Our pioneering monitoring solutions deliver smart data products
                        and services for the management and wellbeing of all animals.</p>
                </div>
                <div class="col-sm-6 col-md-3 item">
                    <ul>
                        <li><a>Akralind 4</a></li>
                        <li><a>201 Kópavogur</a></li>
                        <li><a>Iceland</a></li>
                        <li><a>Tel (+354) 595 3000</a></li>
                        <li><a></a></li>
                        <li><a>Email vaki@merck.com</a></li>
                    </ul>
                </div>
            </div>
            <p class="copyright">Copyright © {{year}} Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights
                reserved.</p>
        </div>
    </footer>
</footer>