import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MyMessage } from 'src/app/classes/my-message';
import { Device } from 'src/app/models/Devices';
import { SmartFlow, SmartFlowInfo } from 'src/app/models/User';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Helper, MyArray } from 'src/app/util/hleper';
import { take } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';


@Component({
  selector: 'app-all-smartflows',
  templateUrl: './all-smartflows.component.html',
  styleUrls: ['./all-smartflows.component.css']
})
export class AllSmartflowsComponent extends MyMessage implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;

  public sfForList: MyArray<SmartFlow> = new MyArray();
  
  constructor(
    public fs: FirestoreService,
    private sfService: FirebaseService,
    public dialog: MatDialog,
    public settings: SettingsService,
    public globalData: GlobalDataService
  ) {
    super();
    if (globalData.allSmartFlows.size() == 0) {
      // We need to trigger a reed of all smartflows
      this.getSmartFlows()
    }
  }

  sfID: string = '';
  sfName: string = '';
  addView = false;
  step: number = 0;
  step_size: number = 50;

  ngOnInit(): void {
    this.showResults();
  }

  getSmartFlows() {
    this.fs
      .getAllSmartFlows()
      .pipe(take(1))
      .subscribe((data) => {
        if (data !== null && data !== undefined) {
          data.forEach((element: any) => {
            // sfInfo is the info the user has added to this smartflow
            // Get smartflows in DB
            // Will later make this better, we can make admin add info
            // about smartflow
            let sfInfo: SmartFlowInfo = {
              id: element.id,
              notify: false,
              name: element.id,
              company: '',
              site: '',
              location: '',
              phoneNumber: '',
              docID: '',
              room: '',
              farm: '',
              city: '',
              country: '',
              address: '',
              description: ''
            }

            element.data.info = sfInfo;
            if (element.data.count === undefined) {
              element.data.count = 0;
            }

            let smartflow: SmartFlow = element.data as SmartFlow;
            if (element !== null && element !== undefined) {
              // This has to happen before we add it.
              // If not we might try to get devices and we
              // get undefined
              this.fs.addDviceInfoToSmartFlow(smartflow);
            } else {
              // Its an old smartlfow, there is no more info about it.
              // Like VAKI86
              smartflow = Helper.createSmartFlowWidthInfo(element);
            }

            this.globalData.allSmartFlows.push(smartflow);
            this.sfForList.push(smartflow);
            // Always sort them afterwords
            //this.globalData.allSmartFlows.all().sort((a, b) => a.info.name.localeCompare(b.info.name));
            // We should move this to the smartflows.component
            this.sfService.linkForGood(
              smartflow,
              'updateTime',
              sfInfo.id + '/SmartFlow/online'
            );

            this.sfService.linkForGood(
              smartflow,
              'session_on',
              sfInfo.id + '/counter/on'
            );
          });
        }
      });
  }

  last() {
    if (this.step > 0) {
      this.step--;
    }
  }

  next() {
    if ((this.step * this.step_size + 1) < this.globalData.allSmartFlows.size()) {
      this.step++;
    }
  }

  track(index: number, sf: SmartFlow) {
    //console.log(sf)
  }

  onKey(event: any) {
    const inputValue = event.target.value;
    this.sfForList.clear()
    this.globalData.allSmartFlows.all().forEach((sf: SmartFlow) => {
      if (!(!sf.info.name.toLowerCase().includes(inputValue.toLowerCase()) && !sf.info.id.toLowerCase().includes(inputValue.toLowerCase()))) {
        this.sfForList.push(sf);
      }
    });
  }

  sortAZ() {
    this.sfForList.all().sort((a, b) => a.info.name.localeCompare(b.info.name));
  }

  sort10() {
    this.sfForList.all().sort((a, b) => (a.count == b.count) ? 0 : ((a.count > b.count)) ? -1 : 1);
  }

  whatTime(time: string): string {
    return Helper.getTimeCSS(time);
  }

  getTimeStr(time: any) {
    if (time !== undefined && time !== null && time !== '') {
      return new Date(time.seconds * 1000).toDateString();
    }

    return '';
  }

  showResults() {
    this.sfForList.clear();
    this.globalData.allSmartFlows.all().forEach((sf: SmartFlow) => {
      if (!(this.getON(sf.session_on) || this.getOnline(sf.updateTime) || this.getGraders(sf.devices) || this.getPumps(sf.devices))) {
        this.sfForList.push(sf);
      }
    });
  }

  getOnline(s: string): boolean {
    return this.settings.settings.allSmartFlows.online
      ? !Helper.isOnline(s)
      : false;
  }

  getON(b: boolean) {
    return this.settings.settings.allSmartFlows.on
      ? b !== this.settings.settings.allSmartFlows.on
      : false;
  }

  getGraders(devices: Device[]) {
    if(!this.settings.settings.allSmartFlows.graders) {
      return false;
    }
    let result = true;
    devices.forEach((device: Device) => {
      if (device.type === 'SmartGrader') {
        result = false;
      }
    });
    return result;
  }

  getPumps(devices: Device[]) {
    if(!this.settings.settings.allSmartFlows.pumps) {
      return false;
    }
    let result = true;
    devices.forEach((device: Device) => {
      if (device.type === 'Pump') {
        result = false;
      }
    });
    return result;
  }

  private delete(docID: string, smartlfow_id: string) {
  }

  onSubmit(ngForm: NgForm) {

  }

  openDialog(docID: string, smartlfow_id: string, smartflow_name: string) {
    const dialogRef = this.dialog.open(AdminSmartFlowDeletDialog, {
      data: { name: smartflow_name, id: smartlfow_id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        console.log(`Delete File : ${docID}`);
        this.delete(docID, smartlfow_id);
      } else {
        console.log(`Cancel File delete: ${docID}`);
      }
    });
  }

  connectTo(id: string, name: string) {
    this.settings.connect.id = id;
    this.settings.connect.name = name;
    this.settings.connect.connect = true;
  }
  
}

@Component({
  selector: './dialog-admin-smartflow-delete.html',
  templateUrl: './dialog-admin-smartflow-delete.html',
  styleUrls: ['./all-smartflows.component.css'],
})
export class AdminSmartFlowDeletDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}