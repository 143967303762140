import { Component, Input, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SmartFlow } from 'src/app/classes/smartflow';
import { ChartModle } from '../chart-model';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-dc-chart',
  templateUrl: './dc-chart.component.html',
  styleUrls: ['./dc-chart.component.css']
})
export class DcChartComponent extends ChartModle {
  @Input()
  smartflow: SmartFlow = new SmartFlow();

  @ViewChild(BaseChartDirective)
  chart: BaseChartDirective | undefined;

  updateCharts(): void {
    if (this.chart !== undefined) {
      this.chart.update();
    }
  }

  init(): void {
    this.data.datasets = [
      {
        label: 'Sensor',
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        data: [], tension: 0.2
      },
      {
        label: 'Water added',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        data: [], tension: 0.2
      },
      {
        label: 'Set point',
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        data: [], tension: 0.2 
      }
    ];

    this.options = {
      scales: {
        y: {
          suggestedMin: 0,
          suggestedMax: 100,
        },
      },
    };

    // This is to update chart, listen for data change on dc
    this.smartflow.dc
      .getSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((p) => {
        if (p.reset) {
          this.clearGraph();
        } else {
          this.addDataPoint(p);
          this.updateCharts();
        }
      });

    this.smartflow.connected.pipe(takeUntil(this.destroyed$)).subscribe((b) => {
      // Listen for smartflow connect. If it is connected
      // we read counter labels.
      if (b) {
        this.addAllDataPoints();
      }
    });
  }


  addAllDataPoints(): void {
    if (this.smartflow.connected.value) {
      this.clearGraph();
      this.smartflow.dc.getDataPoints().forEach((p) => {
        this.addDataPoint(p);
      });
      this.updateCharts();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
