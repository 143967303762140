<mat-toolbar class="mat-elevation-z8">
    <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
            menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
            close
        </mat-icon>
    </button>
    <img src="assets/img/vaki_logo.webp" width="30" height="30" class="d-none d-sm-block d-inline-block align-top m-2 ">
    <h4 class="d-none d-sm-block mt-2">VAKI</h4>
    <div class="span">
        <img src="assets/img/sf_logo.webp" width="36" height="36" class="m-2">SmartFlow Web APP
    </div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8" >

        <app-client></app-client>

        <app-nav-items></app-nav-items>

    </mat-sidenav>
    <mat-sidenav-content>
        <div>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-footer ></app-footer>

