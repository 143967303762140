import { Component, Input, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SmartFlow } from 'src/app/classes/smartflow';
import { ChartModle } from '../chart-model';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-grader-chart',
  templateUrl: './grader-chart.component.html',
  styleUrls: ['./grader-chart.component.css']
})
export class GraderChartComponent extends ChartModle {
  @Input()
  smartflow: SmartFlow = new SmartFlow();

  @ViewChild(BaseChartDirective)
  chart: BaseChartDirective | undefined;

  updateCharts(): void {
    if (this.chart !== undefined) {
      this.chart.update();
    }
  }

  init(): void {
    this.data.datasets = [
      {
        label: 'Small',
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        data: [], tension: 0.1
      },
      {
        label: 'Medium',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        data: [], tension: 0.1
      },
      {
        label: 'Large',
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        data: [], tension: 0.1
      }
    ];

    this.options = {
      scales: {
        y: {
          suggestedMin: 10,
          suggestedMax: 20,
        },
      },
    };

    this.smartflow.grader
      .getSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((p) => {
        if (p.reset) {
          this.clearGraph();
        } else {
          this.addDataPoint(p);
          this.updateCharts();
        }
      });

    this.smartflow.connected.pipe(takeUntil(this.destroyed$)).subscribe((b) => {
      // Listen for smartflow connect. If it is connected
      // we read counter labels.
      if (b) {
        this.addAllDataPoints();
        this.updateCharts();
      }
    });

  }


  addAllDataPoints(): void {
    if (this.smartflow.connected.value) {
      this.clearGraph();
      this.smartflow.grader.getDataPoints().forEach((p) => {
        this.addDataPoint(p);
      });
      this.updateCharts();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
