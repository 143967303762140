import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './component/auth/auth.component';

import { DashboardComponent } from './component/dashboard/dashboard.component';
import { HelpComponent } from './component/help/help.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { SessionComponent } from './component/sessions/session/session.component';
import { SessionsComponent } from './component/sessions/sessions.component';
import { DeviceComponent } from './component/smartflows/smartflow/device/device.component';
import { SmartflowComponent } from './component/smartflows/smartflow/smartflow.component';
import { SmartflowsComponent } from './component/smartflows/smartflows.component';
import { UsersComponent } from './component/users/users.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AllSmartflowsComponent } from './component/admin/all-smartflows/all-smartflows.component';
import { QuickbaseComponent } from './component/admin/quickbase/quickbase.component';

const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'login', component: AuthComponent},
  {path: 'register', component: AuthComponent},
  {path: 'smartflows', component: SmartflowsComponent},
  {path: 'smartflows/smartflow', component: SmartflowComponent},
  {path: 'smartflows/smartflow/device', component: DeviceComponent},
  {path: 'sessions', component: SessionsComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'session', component: SessionComponent},
  {path: 'sessions/session', component: SessionComponent},
  {path: 'admin/users', component: UsersComponent},
  {path: 'admin/quickbase', component: QuickbaseComponent},
  {path: 'admin/smartflows', component: AllSmartflowsComponent},
  {path: 'help', component: HelpComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
