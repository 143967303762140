import { Component, Input} from '@angular/core';
import { SmartFlow } from 'src/app/classes/smartflow';

@Component({
  selector: 'app-grader',
  templateUrl: './grader.component.html',
  styleUrls: ['./grader.component.css']
})
export class GraderComponent {
  @Input()
  smartflow:SmartFlow = new SmartFlow();
  speed: string[] = ['12', '18', '24', '32'];

}
