<div class="card shadow border-start-primary w-100">
    <img class="card-img-top p-2" src="assets/img/sf-all.webp">
    <div class="card-body">
        <h5 *ngIf="smartflow.connected.value" class="text-center text-secondary">{{name}}</h5>
        <h6 *ngIf="smartflow.connected.value" class="text-center pb-4 pt-1">
            <!--<span class="text-secondary">Data upload : </span>-->
            <span
                [ngClass]="(whatTime(smartflow.smartflow.data.online))">{{formatTime(smartflow.smartflow.data.online)}}</span>
        </h6>
        <form #clientForm="ngForm" name="myForm">
            <div *ngIf="!smartflow.connected.value" class="form-group mb-3">
                <mat-form-field class="w-100">
                    <mat-label>SmartFlow ID</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" name="id" #smartflowID="ngModel"
                        [ngClass]="{'is-invalid':smartflowID.errors && smartflowID.touched}" [(ngModel)]="id"
                        minlength="{{this.idLength}}" #input maxlength="{{this.idLength}}"
                        oninput="this.value = this.value.toUpperCase()" required>
                    <button mat-icon-button type='button' matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint align="end">{{input.value.length || 0}}/{{this.idLength}}</mat-hint>
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button color="primary" [ngClass]="(['mt-2', 'col-12'])" type="submit"
                    [class.spinner]="smartflow.connecting"
                    [disabled]="!smartflow.connected.value && (smartflow.connecting || id.length === 0)"
                    (click)="connect()">{{!smartflow.connected.value?"Connect":"Disconnect"}}</button>
            </div>

            <div *ngIf="message" class="alert mt-4" [ngClass]="message_type" role="alert">
                {{errorMessage}}
            </div>
        </form>
        <section *ngIf="smartflow.connected.value">
            <mat-divider></mat-divider>
            <section class="row" [ngClass]="(smartflow.smartflow.data.running? 'text-success' : 'text-secondary')">
                <div class="col me-4">
                    <div *ngIf="smartflow.smartflow.data.sp1 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.counter.data.channel1.name}}</span>
                    </div>
                    <div *ngIf="smartflow.smartflow.data.sp2 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.counter.data.channel2.name}}</span>
                    </div>
                    <div *ngIf="smartflow.smartflow.data.sp3 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.counter.data.channel3.name}}</span>
                    </div>
                    <div *ngIf="smartflow.smartflow.data.sp4 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.counter.data.channel4.name}}</span>
                    </div>
                </div>
                <div class="col me-4">
                    <div *ngIf="smartflow.smartflow.data.sp1 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.smartflow.data.sp1}}</span>
                    </div>
                    <div *ngIf="smartflow.smartflow.data.sp2 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.smartflow.data.sp2}}</span>
                    </div>
                    <div *ngIf="smartflow.smartflow.data.sp3 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.smartflow.data.sp3}}</span>
                    </div>
                    <div *ngIf="smartflow.smartflow.data.sp4 !== 'null'"
                        class="text-uppercase text-xs mb-1 text-center">
                        <span>{{smartflow.smartflow.data.sp4}}</span>
                    </div>
                </div>
                <h6 *ngIf="smartflow.smartflow.data.runTime !== 'null'" class="mt-3 text-center">Runtime:
                    {{smartflow.smartflow.data.runTime}}</h6>
            </section>
            <mat-divider></mat-divider>
        </section>
        <section class="mt-3" *ngIf="smartflow.connected.value">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Chart Interval</mat-label>
                <mat-select [(ngModel)]="selectedIntervalIndex" name="chartInterval" (selectionChange)="onIntervalChange()">
                    <mat-option *ngFor="let interval of chartIntervals; let idx = index" [value]="idx">
                        {{ interval }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Max Scale Dropdown -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Counter Chart Max</mat-label>
                <mat-select [(ngModel)]="selectedMaxScaleIndex" name="maxScale" (selectionChange)="onMaxScaleChange()">
                    <mat-option *ngFor="let scale of maxScales; let idx = index" [value]="idx">
                        {{ scale }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
    </div>
</div>