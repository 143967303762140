import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment'; // Adjust the path to match your structure

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  year: number = new Date().getFullYear();
  version: string = environment.version;

  constructor() { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

}