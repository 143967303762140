import { BehaviorSubject } from 'rxjs';
import { Helper } from '../util/hleper';
import { DataCollector } from './data-collector';
import { Point } from './point';

interface Counter {
  capacity: number;
  max: number;
  name: string;
  nr: number;
  on: boolean;
  total: number;
  channel1: Channel;
  channel2: Channel;
  channel3: Channel;
  channel4: Channel;
}

export interface Channel {
  bio: string;
  count: number;
  flow: number;
  max: number;
  name: string;
  percentage: number;
  size: string;
}

export class CounterClass extends DataCollector {

  data: Counter = {
    capacity: 0,
    max: 0,
    name: 'null',
    nr: 4,
    on: false,
    total: 0,
    channel1: {
      bio: '',
      count: 0,
      flow: -1,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
    channel2: {
      bio: '',
      count: 0,
      flow: -1,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
    channel3: {
      bio: '',
      count: 0,
      flow: -1,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
    channel4: {
      bio: '',
      count: 0,
      flow: -1,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
  };

  defult: Counter = {
    capacity: 0,
    max: 0,
    name: 'null',
    nr: 4,
    on: false,
    total: 0,
    channel1: {
      bio: '',
      count: 0,
      flow: 0,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
    channel2: {
      bio: '',
      count: 0,
      flow: 0,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
    channel3: {
      bio: '',
      count: 0,
      flow: 0,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
    channel4: {
      bio: '',
      count: 0,
      flow: 0,
      max: 0,
      name: '',
      percentage: 0,
      size: '',
    },
  };

  // For calculating count diff
  private last: Point = new Point([0, 0, 0, 0]);
  private diff: Point = new Point([0, 0, 0, 0]);
  private onSubject = new BehaviorSubject(false);

  getOnSubject(): BehaviorSubject<boolean> {
    return this.onSubject;
  }

  getTotalFlow() {
    let sum = 0;
    for(let i = 1; i <= 4; i++) {
      let c = this.data[('channel' + i) as keyof Counter] as Channel;
      if(c?.flow !== undefined && c.flow > -1) {
        sum = sum + c.flow ;
      }
    }
    return sum;
  }

  constructor() {
    super();
    const that = this;
    this.data = new Proxy(this.data, {
      // Overright set command.
      set: function (target: any, key: any, value: any) {
        switch (key) {
          case 'on': {
            // To notify anyone that its on who is listening
            that.onSubject.next(value);
            break;
          }
        }
        // We set the value as normal
        target[key] = value;
        return true;
      },
    });
  }

  // Will come later
  public setAll(): void { }

  public gatDataPoint(load: boolean): Point {
    for (let i = 0; i < 4; i++) {
      this.getLastCount(i, load);
    }
    return this.diff;
  }

  // We need to init some things before we can run the loop
  public init(): void {
    for (let ch = 0; ch < 4; ch++) {
      let temp = this.get(`channel${ch + 1}`);
      if (temp !== undefined) {
        let now: Channel = temp;
        this.last.ch[ch] = now.count;
      }
    }
  }

  getLastCount(ch: number, load: boolean) {
    let temp = this.get(`channel${ch + 1}`);
    let diff = 0;
    if (temp !== undefined) {
      let now: Channel = temp;
      diff = now.count - this.last.ch[ch];
      this.last.ch[ch] = now.count;

      if (diff < 0) {
        diff = 0;
      }

      if (diff > 10000) {
        diff = 0;
      }
    }
    // If its loaded then we have update time 60, else we have 15
    this.diff.ch[ch] = load ? diff : diff / (this.updateTime / 60);
  }

  get(arg0: string): any {
    return this.data[arg0 as keyof Counter];
  }

  reset() {
    Helper.copy(this.defult, this.data);

    this.last.clear();
    this.diff.clear();

    // Let anyone listening to reset there list
    this.subject.next(new Point([0, 0, 0, 0], true));
    this.dataClear();
    this.stopCollection();
  }

  clear() {
    // Let anyone listening to reset there list
    this.subject.next(new Point([0, 0, 0, 0], true));
    this.dataClear();
  }

  setConnected() {
    console.log('Counter connected!');
    this.init();
  }

  isConnected() {
    return this.data.name !== 'null';
  }

  setValueChange(arg0: { set: (target: any, key: any, value: any) => boolean; }) {
    this.data = new Proxy(this.data, arg0);
  }

  setChannelValueChange(arg0: { set: (target: any, key: any, value: any) => boolean; }) {
    this.data.channel4 = new Proxy(this.data.channel4, arg0);
  }
}
