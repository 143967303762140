<div class="card shadow border-start-primary w-100" [ngClass]="smartflow.pump.data.auto ? 'border-success text-success' :''">
    <div [ngClass]="(smartflow.pump.offline || smartflow.pump.data.name === 'null'? 'bg-secondary text-white' : 'text-success')"
        class="card-header text-center">
        <h6 class="fw-bold m-0">
            {{smartflow.pump.data.name ===
            'null'? "Pump Disconnected": smartflow.pump.data.name}}</h6>
    </div>
    <div class="card-body">
        <div class="chart-container">
            <ngx-gauge [type]="gaugeType" 
                    [value]="gaugeValue" 
                    [label]="gaugeLabel"  
                    [append]="gaugeAppendText">
            </ngx-gauge>
            <mat-divider></mat-divider>
        </div>

        <section class="row align-items-center">
            <div class="col me-4">
                <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Main</span></div>
                <div class="text-dark h5 text-center"><span style="font-size: 36px;"
                        [ngClass]="(smartflow.pump.data.mainOn ? 'text-success' : 'text-danger')">{{smartflow.pump.data.mainOn
                        ?
                        "ON":"OFF"}}</span>
                </div>
            </div>
            <div class="col me-4">
                <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Primer</span></div>
                <div class="text-dark h5 text-center"><span style="font-size: 36px;"
                        [ngClass]="(smartflow.pump.data.primerOn ? 'text-success' : 'text-danger')">{{smartflow.pump.data.primerOn
                        ?
                        "ON":"OFF"}}</span>
                </div>
            </div>
        </section>
        <section *ngIf="smartflow.pump.data.gain !== -1" name="regulate">
            <mat-divider></mat-divider>
            <section class="row align-items-center">
                <div class="col me-2">
                    <div class="text-uppercase text-primary text-xs mb-1  text-center"><span>Regulate
                            fish/min</span></div>
                </div>
                <div class="col me-2">
                    <div class="text-dark h5 mb-2 text-center"><span style="font-size: 24px;"
                            [ngClass]="(smartflow.pump.data.auto ? 'text-success' : 'text-danger')">{{smartflow.pump.data.auto
                            ?
                            "AUTO":"OFF"}}</span></div>
                </div>
            </section>
            <mat-divider></mat-divider>
            <section class="row align-items-center">
                <div class="col me-4">
                    <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Target</span></div>
                    <div class="text-dark h5 text-center"><span
                            style="font-size: 36px;">{{smartflow.pump.data.setFlowRate}}</span></div>
                </div>
                <div class="col me-4">
                    <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Output</span></div>
                    <div class="text-dark h5 text-center"><span
                            style="font-size: 36px;">{{smartflow.pump.data.flowRate}}</span>
                    </div>
                </div>
            </section>
            <mat-divider></mat-divider>
            <section class="row align-items-center">
                <div class="col me-2">
                    <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Channel</span></div>
                </div>
                <div class="col me-2">
                    <div class="text-dark h5 text-center"><span style="font-size: 20px;"
                            class="text-primary">{{smartflow.pump.data.flowChannel}}</span></div>
                </div>

            </section>
        </section>

    </div>
</div>