import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { MyMessage } from 'src/app/classes/my-message';
import { TimeStamp } from 'src/app/models/Devices';
import { SmartFlow } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Helper } from 'src/app/util/hleper';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-smartflows',
  templateUrl: './smartflows.component.html',
  styleUrls: ['./smartflows.component.css']
})
export class SmartflowsComponent extends MyMessage implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;

  constructor(
    public fireStoreService: FirestoreService,
    public auth: AuthService,
    public dialog: MatDialog,
    public settings: SettingsService,
    public globalData: GlobalDataService, 
    private route: ActivatedRoute
  ) {
    super();
  }

  sfID: string = '';
  sfName: string = '';
  addView = false;
  expanded = false;

  ngOnInit(): void {
    this.showResults();
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params !== null && params !== undefined) {
        this.sfID = (this.expanded = params['id'] === undefined) ? '' : params['id'];
        this.sfName = params['name'] === undefined ? '' : params['name'];
        this.expanded = !this.expanded;
      }
    });
  }

  ngAfterViewInit() {
    //We loading the player script on after view is loaded
  }

  getTime(time: TimeStamp) {
    return Helper.getTime(time); 
  }

  onKey(event: any) {
    const inputValue = event.target.value;
    this.globalData.usersSmartFlows.all().forEach((sf: SmartFlow) => {
      sf.hide = !sf.info.name.toLowerCase().includes(inputValue.toLowerCase()) && !sf.info.id.toLowerCase().includes(inputValue.toLowerCase());
    });
  }

  whatTime(time: string): string {
    return Helper.getTimeCSS(time);
  }

  getTimeStr(time: any) {
    // Date.prototype.getTimezoneOffset() Returns the time-zone offset in minutes for the current locale.
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
    if (time !== undefined && time !== null && time !== '') {
      return new Date(time.seconds * 1000).toDateString();
    }

    return '';
  }

  showResults() {
    this.globalData.usersSmartFlows.all().forEach((sf: SmartFlow) => {
      sf.hide = this.getON(sf.session_on) || this.getOnline(sf.updateTime);
    });
  }

  getOnline(s: string): boolean {
    return this.settings.settings.smartflowsList.online
      ? !Helper.isOnline(s)
      : false;
  }

  getON(b: boolean) {
    return this.settings.settings.smartflowsList.on
      ? b !== this.settings.settings.smartflowsList.on
      : false;
  }

  private delete(docID: string, smartlfow_id: string) {
    this.auth.removeSmartFlow(docID, smartlfow_id);
  }

  onSubmit(ngForm: NgForm) {
    const name = ngForm.value.name;
    this.auth
      .addSmartFlow(
        Helper.getSmartFlowInfoWithNameAndID(ngForm.value.id, ngForm.value.name)
      )
      .then(
        (res) => {
          // Send Varification link in email
          console.log(res);
          if (res) {
            this.showMessage(
              'success',
              'SmartFlow ' + name + ' has been successfully added.',
              4000
            );
          }
        },
        (err) => {
          this.showMessage(
            'danger',
            'Faild to add SmartFlow ' + name + '.',
            4000
          );
        }
      );
  }

  openDialog(docID: string, smartlfow_id: string, smartflow_name: string) {
    const dialogRef = this.dialog.open(SmartFlowDeletDialog, {
      data: { name: smartflow_name, id: smartlfow_id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        console.log(`Delete File : ${docID}`);
        this.delete(docID, smartlfow_id);
      } else {
        console.log(`Cancel File delete: ${docID}`);
      }
    });
  }

  connectTo(id:string,name:string) {
    console.log('Connect to');
    this.settings.connect.id = id;
    this.settings.connect.name = name;
    this.settings.connect.connect = true;
  }
}

@Component({
  selector: 'dialog-smartflow-delete.html',
  templateUrl: 'dialog-smartflow-delete.html',
  styleUrls: ['./smartflows.component.css'],
})
export class SmartFlowDeletDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}