import { Component, Input, OnInit } from '@angular/core';
import { NgxGaugeType } from 'ngx-gauge/gauge/gauge';
import { ReplaySubject, takeUntil } from 'rxjs';
import { SmartFlow } from 'src/app/classes/smartflow';

@Component({
  selector: 'app-pump',
  templateUrl: './pump.component.html',
  styleUrls: ['./pump.component.css']
})
export class PumpComponent implements OnInit {
  @Input()
  smartflow: SmartFlow = new SmartFlow();

  gaugeType:NgxGaugeType = "semi";
  gaugeValue = 28.3;
  gaugeLabel = "Speed";
  gaugeAppendText = "%";

  constructor() {
  }

  test = Math.floor(Math.random() * 100);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  ngOnInit(): void {
    // We need to process the speed more then othere values because 
    // we are controlling the speed guge
    this.smartflow.pump.getSpeedSubject().pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.gaugeValue = value;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
