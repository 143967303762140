import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { MyMessage } from 'src/app/classes/my-message';
import { Device, TimeStamp } from 'src/app/models/Devices';
import { SmartFlowInfo } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { Helper } from 'src/app/util/hleper';

@Component({
  selector: 'app-smartflow',
  templateUrl: './smartflow.component.html',
  styleUrls: ['./smartflow.component.css']
})
export class SmartflowComponent extends MyMessage {

  devices: Device[] = [];

  found$: ReplaySubject<boolean> = new ReplaySubject(1);

  companyAutofilled!: boolean;
  cityAutofilled!: boolean;
  addressAutofilled!: boolean;
  countryAutofilled!: boolean;

  smartflowInfo: SmartFlowInfo = Helper.getEmptySmartFlowInfo();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(
    private route: ActivatedRoute,
    private myFireStore: FirestoreService,
    private _ngZone: NgZone,
    public auth: AuthService, 
    public dialog: MatDialog, 
    public global_data: GlobalDataService
  ) {
    super();
    // pipe(take(1)) to make sure we only run it once
    this.route.queryParams.subscribe((params) => {
      if (params !== null && params !== undefined) {
        this.smartflowInfo.name =
          params['name'] === undefined ? '' : params['name'];
        this.smartflowInfo.id = params['id'] === undefined ? '' : params['id'];

        if (
          this.smartflowInfo.id !== '' &&
          this.smartflowInfo.id.length === 6
        ) {

          // Endurskoða þetta. Skrifa allt aftur í firestore smartflows/smartflow. 
          // Fjarlæga yfir classa smartflow
          this.global_data.usersSmartFlows
            .getSubject()
            .pipe(takeUntil(this.found$))
            .subscribe((a:any[]) => {
              if (a) {
                const found = a.find((s) => s.info.id === this.smartflowInfo.id);
                if (found !== undefined) {
                  this.smartflowInfo = found.info;
                  this.devices = found.devices;
                  this.found$;
                }
              }
            });
        }
      }
    });
  }

  private update() {
    return this.auth.updateSmartFlow(this.smartflowInfo);
  }

  getTime(time: TimeStamp) {
    return Helper.getTime(time);
  }

  deleteDevice(device: Device) {
    return this.myFireStore.deleteDevice(
      this.smartflowInfo.id,
      device.id,
      device.type
    );
  }

  // Get connect image for device,
  // If the only info is Counter a defult counter image is given
  // and the same thing for Grader.
  getNewImage(device: Device) {
    let extra = ""
    if(device.type === "Pump") {
      extra = "" + device["size" as keyof Device];
    }

    if(device.type === "Counter") {
      if (device.id.toLowerCase().includes('micro')) {
        return Helper.image["Micro"];
      } if (device.id.toLowerCase().includes('macro')) {
        return Helper.image["Macro"];
      }
    }

    if(device.type === "SmartGrader") {
      // Grader size are (0 = 10 track, 1 = 5 track and 2 = 7 track)
      if("" + device["size" as keyof Device] === "1") {
        extra = "5";
      }
    }

    return Helper.image[device.type + extra];
  }

  onSubmit() {
    this.update().then(
      (res) => {
        // Send Varification link in email
        console.log(res);
        this.showMessage(
          'success',
          'Smartflow ' +
            this.smartflowInfo.id +
            ' has been successfully updated.',
          4000
        );
      },
      (err) => {
        this.showMessage(
          'danger',
          'Faild to update SmartFlow ' + this.smartflowInfo.id + '.',
          4000
        );
      }
    );
  }

  deleteDialog(device: Device) {
    const dialogRef = this.dialog.open(DialogDeviceDelete, {
      data: { id: device.id, type: device.type },
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteDevice(device).then(
          (res) => {
            this.devices = this.devices.filter((obj) => {
              return obj.id !== device.id;
            });
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  itsFound() {
    this.found$.next(true);
    this.found$.complete();
  }

  ngOnDestroy() {
    this.itsFound();
  }
}

@Component({
  selector: 'dialog-device-delete',
  templateUrl: 'dialog-device-delete.html',
  styleUrls: ['smartflow.component.css'],
})
export class DialogDeviceDelete {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
