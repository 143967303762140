import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MyRout } from './my-rout';


@Component({
  selector: 'app-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.css']
})
export class NavItemsComponent implements OnInit, AfterViewInit {
  step: number = 0;

  myRout: MyRout = {
    '': 0,
    'smartflows': 1,
    'sessions': 2,
    'devices': 3,
    'admin/users': 5,
    'register': 6,
    'login': 7,
    'help': 8,
    'profile': 9,
    'admin/smartflows':10,
    'admin/quickbase':11
  };

  constructor(public authService: AuthService, private router: Router) {}

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.step = this.myRout[event.url.substring(1) as keyof MyRout];
      }
    });
  }

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  nav(i: number) {
    this.step = i;
    console.log(this.step);
  }
}
