import { ReplaySubject, takeUntil } from 'rxjs';
import { LocatorService } from '../../services/locator.service';
import { SettingsService } from '../../services/settings.service';
import { Point } from '../../util/point';
import { ChartData, ChartOptions } from 'chart.js';


export abstract class ChartModle {
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  abstract init(): void;
  abstract addAllDataPoints(): void;
  abstract updateCharts(): void;

  settings: SettingsService = LocatorService.injector.get(SettingsService); // Do not need can just add in constrocter

  last: number = 100;
  first_run: boolean = true;
  timeArray: number[] = [];

  constructor() {
    setTimeout(() => {
      this.init()
    }, 1000);

    this.settings.update.pipe(takeUntil(this.destroyed$)).subscribe((b) => {
      // Settings changesed lets update if needed
      if (!b && !this.first_run) {
        // If its false then we update x-axes, later use number for more updates options
        this.clearGraph();
        this.addAllDataPoints(); // We do it like this because the stored points are every minute.
        this.updateCharts();
      }
      this.first_run = false;
    });
  }

  // Blank config for chart
  data: ChartData<'line'> = {
    labels: [],
    datasets: [
      { label: 'Ch1', data: [], tension: 0.5 },
      { label: 'Ch2', data: [], tension: 0.5 },
      { label: 'Ch3', data: [], tension: 0.5 },
      { label: 'Ch4', data: [], tension: 0.5 },
    ],
  };

  // Chart options
  options: ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        suggestedMax: 100,
      },
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  /**
   * Adds a new data point to the chart's datasets and labels. 
   * It also ensures the number of data points do not exceed the maximum allowed.
   * @param p - The Point object containing the data and time to be added to the chart.
   */
  addDataPoint(p: Point) {
    // Iterate through each channel in the provided point 'p'
    for (let i = 0; i < p.ch.length; i++) {
      // Add the data from the channel to the respective dataset
      this.data.datasets[i].data.push(p.ch[i]);
    }

    // Add the timestamp to the labels (doing this for each channel seems redundant though)
    this.timeArray.push(p.timeStamp.seconds);
    this.data.labels?.push(p.time);

    // Make sure the chart is not growing out of its range
    this.removeData();
  }

  /**
   * Removes the oldest data point and label from the chart's datasets and labels respectively.
   */
  removeData() {
    if (this.timeArray.length > 1) {
      let t0 = this.timeArray[0];
      let t1 = this.timeArray[this.timeArray.length - 1];

      // Check if the dataset exceeds the maximum allowed data points
      while (Math.floor((t1 - t0) / 60) >= this.settings.settings.maxPoints && this.timeArray.length > this.settings.settings.maxPoints) {

        // Iterate through each dataset and remove the oldest data point
        this.data.datasets.forEach(dataset => {
          dataset.data.shift();
        });

        // Use optional chaining to ensure labels exist before removing the oldest label
        this.data.labels?.shift();
        this.timeArray.shift();
        t0 = this.timeArray[0];
      }
    }
  }

  updateMax(n: number) {
    if (n != 0) {
      this.last = n;
      this.options.scales = {
        y: {
          beginAtZero: true,
          min: 0,
          max: n,
        },
      };
    } else {
      this.options.scales = {
        y: {
          beginAtZero: true,
          min: 0,
          suggestedMax: this.last,
        },
      };
    }
  }

  clearGraph() {
    this.data.labels = [];
    this.timeArray = [];
    this.data.datasets.forEach(function (dataset) {
      while (dataset.data.length > 0) {
        dataset.data.pop();
      }
    });
    this.updateCharts();
  }
}
