import { Component, Input, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SmartFlow } from 'src/app/classes/smartflow';
import { ChartModle } from '../chart-model';
import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-pump-chart',
  templateUrl: './pump-chart.component.html',
  styleUrls: ['./pump-chart.component.css']
})
export class PumpChartComponent extends ChartModle {
  @Input()
  smartflow: SmartFlow = new SmartFlow();

  @ViewChild(BaseChartDirective)
  chart: BaseChartDirective | undefined;

  updateCharts(): void {
    if(this.chart !== undefined) {
      this.chart.update();
    }
  }

  init(): void {
    // Init the chart config file.
    this.data.datasets = [
      {
        label: 'Speed',
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        data: [], tension: 0.1 
      }
    ];

    this.options = {
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    };

    // Listen for data comming from pumps data collection
    this.smartflow.pump
    .getSubject()
    .pipe(takeUntil(this.destroyed$))
    .subscribe((p) => {
        // We have a data point p
        if (p.reset) {
          this.clearGraph();
        } else {
          this.addDataPoint(p);
          this.updateCharts();
        }
    });

    this.smartflow.connected.pipe(takeUntil(this.destroyed$)).subscribe((b) => {
      // Listen for smartflow connect. If it is connected
      // we read counter labels.
      if (b) {
        this.addAllDataPoints();
        this.updateCharts();
      }
    });
  }

  addAllDataPoints(): void {
    if (this.smartflow.connected.value) {
      this.clearGraph();
      this.smartflow.pump.getDataPoints().forEach((p) => {
        this.addDataPoint(p);
      });
      this.updateCharts();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  constructor() {
    super();
  }
}
