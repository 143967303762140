import { Helper } from './hleper';
import { DataCollector } from './data-collector';
import { Point } from './point';
import { BehaviorSubject } from 'rxjs';

interface DC {
  autoOn: boolean;
  fishPipe: number;
  gain: number;
  height: number;
  name: string;
  p_on: boolean;
  plc: number;
  sp: number;
  tauto: boolean;
  tsp: number;
  water: number;
}

export class DCClass extends DataCollector {
  public setAll(): void {
  }
  public gatDataPoint(load: boolean): Point {
    return new Point([this.data.plc, this.data.water, this.data.sp * (this.data.autoOn ? 0 : 1),]);
  }
  public init(): void {
  }

  private waterSubject = new BehaviorSubject(0);

  data: DC = {
    autoOn: true, // This value is invers. True is off and False is on
    fishPipe: 0,
    gain: 0,
    height: 0,
    name: 'null',
    p_on: false,
    plc: 0,
    sp: 0,
    tauto: false,
    tsp: 0,
    water: 0,
  };

  defult: DC = {
    autoOn: true,
    fishPipe: 0,
    gain: 0,
    height: 0,
    name: 'null',
    p_on: false,
    plc: 0,
    sp: 0,
    tauto: false,
    tsp: 0,
    water: 0,
  };

  constructor() {
    super();

    let that = this;
    this.data = new Proxy(this.data, {
      // Overright set command.
      set: function (target: any, key: any, value: any) {
        switch (key) {
          case 'water': {
            // Speed is set, lets notify the subject if something
            // has subscribed to it.
            that.waterSubject.next(value);
            break;
          }
        }
        // We set the value as normal
        target[key] = value;
        return true;
      },
    });
  }



  getWaterSubject() {
    return this.waterSubject;
  }

  reset() {
    Helper.copy(this.defult, this.data);
    // Here we add a 0 data point with true to clear. 
    // Meaing it clear the graph
    this.subject.next(new Point([0, 0, 0], true));
    this.dataClear();
    this.stopCollection();
  }

  clear() {
    this.subject.next(new Point([0, 0, 0], true));
    this.dataClear();
  }

}
