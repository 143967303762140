import { BehaviorSubject } from 'rxjs';
import { CounterClass } from '../util/counter';
import { DCClass } from '../util/dc';
import { GraderClass } from '../util/grader';
import { PumpClass } from '../util/pump';
import { Session, SmartFlowClass } from '../util/smartflow';
import { LocatorService } from '../services/locator.service';
import { SettingsService } from '../services/settings.service';
import { Timestamp } from 'firebase/firestore';


export class SmartFlow {
  public settings: SettingsService = LocatorService.injector.get(SettingsService);

  public smartflow: SmartFlowClass = new SmartFlowClass();
  public counter: CounterClass = new CounterClass();
  public grader: GraderClass = new GraderClass();
  public pump: PumpClass = new PumpClass();
  public dc: DCClass = new DCClass();


  public session: Session = {
    on: this.counter.data.on,
  };

  name = "";
  id = "";
  connected = new BehaviorSubject(false);
  connecting = false;

  constructor() {
    // At defult the graph has one data points,
    // We clear them here
    this.clear();
  }

  // For data points
  updateDataPoints() {
  }

  // This here is to sinc changes to all, so they all have same time
  // This is only used for adding not in realtime. So data from backend
  addDataPoints(time: Timestamp) {
    this.counter.addDataPointWithLable(time);
    this.grader.addDataPointWithLable(time);
    this.pump.addDataPointWithLable(time);
    this.dc.addDataPointWithLable(time);
  }

  clear() {
    this.grader.clear();
    this.counter.clear();
    this.pump.clear();
    this.dc.clear();
  }

  reset() {
    this.grader.reset();
    this.counter.reset();
    this.pump.reset();
    this.dc.reset();
    this.connected.next(false);
  }

  setConnected(b: boolean) {
    this.connected.next(b);
  }
}
