import { Helper } from 'src/app/util/hleper';
import { TimeStamp } from '../models/Devices';

export class Point {
  ch:number[] = [];
  timeStamp:TimeStamp;
  time:string = '';
  reset:boolean = false;

  constructor(arg: number[]);
  constructor(arg: number[], reset: boolean);
  constructor(arg: number[], reset: boolean,time:string);
  public constructor(...args: any[]) {
    this.setTime();

    if (args.length === 1) {
      this.copy(args[0]);
      // Laga seinna, cant use = args[0]
      return;
    }
    if (args.length === 2) {
      this.copy(args[0]);

      this.reset = args[1];
      return;
    }
    if (args.length === 3) {
      this.copy(args[0]);

      this.reset = args[1];
      this.time = args[2];
      return;
    }
  }

  public addTime(t: TimeStamp) {
    this.time = Helper.getOnlyTime(t);
    this.timeStamp = t;
  }

  private copy(arg: number[]) {
    arg.forEach((e: number) => {
      this.ch?.push(e);
    });
  }

  clear() {
    this.ch = [];
    this.reset = true;
  }

  setTime() {
    this.time = new Date().toTimeString().substring(0, 8);
    this.timeStamp = Helper.generateTimeStamp();
    this.reset = false;
  }
}
