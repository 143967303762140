import { Component, OnInit } from '@angular/core';
import { SmartFlow } from 'src/app/classes/smartflow';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { Helper } from 'src/app/util/hleper';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent {
  smartflow: SmartFlow = new SmartFlow();
  name: string = '';
  id: string = '';

  cvs: number[][] = [];
  time: string[] = []
  size: number[][] = [];

  constructor(private fs: FirestoreService, private _location: Location, private route: ActivatedRoute) {
    // pipe(take(1)) to make sure we only run it once
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params !== null && params !== undefined) {
        this.name = params['name'] === undefined ? '' : params['name'];
        this.id = params['id'] === undefined ? '' : params['id'];

        if (this.id !== undefined) {
          this.getSession();
        }
      }
    });
  }

  backClicked() {
    this._location.back();
  }

  getSession() {
    this.smartflow.settings.setUpdateTime(24 * 60); // 24 hours
    this.fs
      .getSession(this.id)
      .pipe(take(1))
      .subscribe((data) => {
        if (data !== null && data !== undefined) {
          data.forEach(d => {
            if ('data' in d && 'device' in d.data && typeof d.data.device !== undefined) {
              if (d.data.device === null) {
              } else {
                Object.keys(d.data.device).forEach(dd => {
                  switch (dd) {
                    case 'counter': {
                      this.smartflow.counter.data = d.data.device.counter;
                      break;
                    }
                    case 'SmartFlow': {
                      this.smartflow.smartflow.data = d.data.device.SmartFlow;
                      break;
                    }
                    case 'grader': {
                      this.smartflow.grader.data = d.data.device.grader;
                      break;
                    }
                    case 'pump': {
                      this.smartflow.pump.data = d.data.device.pump;
                      break;
                    }
                    case 'dc': {
                      this.smartflow.dc.data = d.data.device.dc;
                      break;
                    }
                  }
                });
                // Add the new datapoints to array.
              }
              // If device is null we just duplicate the point with new time
              this.smartflow.addDataPoints(d.data.sort);
              // This here is for further processing. We could later add charts with extra data.
              this.processData(Helper.getOnlyTime(d.data.sort));
            }
          });
          // Show all data points
          this.smartflow.setConnected(true);
        }
      });
  }

  public processData(time: string): void {
    // Here we process the data for each point. I want to make arrays
    // So we can press a button download data.
    // We will do counter count and biomas, grader size and pump speed.
    let bios = [this.smartflow.counter.data.channel1?.bio.split('g n:'),
    this.smartflow.counter.data.channel2?.bio.split('g n:'),
    this.smartflow.counter.data.channel3?.bio.split('g n:'),
    this.smartflow.counter.data.channel4?.bio.split('g n:')];

    let temp_data: number[] = [];
    for (let i = 0; i < 4; i++) {
      if (bios[i] !== undefined) {
        if (bios[i].length === 1) {
          temp_data.push(0);
          temp_data.push(0);
        } else if (bios[i].length === 2) {
          temp_data.push(+(bios[i][0].replace(',', '.')));
          temp_data.push(+(bios[i][1].replace(',', '.')));
        }
      }
    }
    temp_data.push(this.smartflow.counter.data?.channel1?.count)
    temp_data.push(this.smartflow.counter.data?.channel2?.count)
    temp_data.push(this.smartflow.counter.data?.channel3?.count)
    temp_data.push(this.smartflow.counter.data?.channel4?.count)

    temp_data.push(this.smartflow.counter.data.total)

    const flow1 = this.smartflow.counter.data?.channel1?.flow;
    const flow2 = this.smartflow.counter.data?.channel2?.flow;
    const flow3 = this.smartflow.counter.data?.channel3?.flow;
    const flow4 = this.smartflow.counter.data?.channel4?.flow;

    temp_data.push(flow1 === undefined ? -1 : flow1)
    temp_data.push(flow2 === undefined ? -1 : flow2)
    temp_data.push(flow3 === undefined ? -1 : flow3)
    temp_data.push(flow4 === undefined ? -1 : flow4)

    temp_data.push(this.smartflow.grader.data.small)
    temp_data.push(this.smartflow.grader.data.medium)
    temp_data.push(this.smartflow.grader.data.large)

    temp_data.push(this.smartflow.pump.data.speed)

    temp_data.push(this.smartflow.dc.data.plc)
    temp_data.push(this.smartflow.dc.data.water)
    temp_data.push(this.smartflow.dc.data.sp)

    this.time.push(time);
    this.cvs.push(temp_data);
  }

  public downloadCSV() {
    var filename;
    var csv = "time\tBio mass 1\tSample size 1\tBio mass 2\tSample size 2\tBio mass 3\tSample size 3\tBio mass 4\tSample size 4" +
      "\tCount 1\tCount 2\tCount 3\tCount 4\tTotal" +
      "\tFlow 1\tFlow 2\tFlow 3\tFlow 4" +
      "\tSmall\tMedium\tLarge\tSpeed\tSensor\tWater_added\tSet_point\n";

    this.cvs.forEach((value, i) => {
      csv += this.time[i]
      value.forEach((d) => {
        csv += '\t' + d
      });
      csv += "\n";
    });

    if (csv == null) return;

    filename = this.id + '.txt';
    this.download(filename, csv)
  }

  public download(file_name: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', file_name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public openPDF(): void {
    var doc = new jsPDF('p', 'mm');
    this.html2canvas(doc, "print1").then(() => {
      doc.addPage();
      this.html2canvas(doc, "print2").then(() => {
        doc.save('smartflow_report.pdf');
      });
    });
  }

  private html2canvas(doc: any, element: string): Promise<void> {
    let DATA: any = document.getElementById(element);
    var promise = new Promise<void>((resolve) => {
      html2canvas(DATA).then((canvas) => {
        const FILEURI = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 280;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var position = 10;
        doc.addImage(FILEURI, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(FILEURI, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        resolve();
      });
    });
    return promise;
  }
}