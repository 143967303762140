<div class="d-flex justify-content-center">
    <h2 *ngIf="selected.customer_id_ == -1" class="mx-4 mt-2">Quickbase customers</h2>
    <h2 *ngIf="selected.customer_id_ != -1" class="mx-4 mt-2" [innerHTML]="selected.customer"></h2>
</div>

<div class="content">
    <div class="form-inline d-flex justify-content-center col-12 btn-group" role="group"
        aria-label="Basic radio toggle button group" style="max-width: 600px;">
        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [checked]="view1"
            (click)="view(0)">
        <label class="btn btn-outline-primary col-4" for="btnradio1">Customer</label>

        <input [disabled]="selected.customer_id_ == -1" type="radio" class="btn-check" name="btnradio" id="btnradio2"
            autocomplete="off" [checked]="view2" (click)="view(1)">
        <label class="btn btn-outline-primary col-4" for="btnradio2">Devices/Service</label>

        <!--<input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" [checked]="view3"
            (click)="view(2)">
        <label class="btn btn-outline-primary col-4" for="btnradio3">Details</label>-->
    </div>
</div>
<section *ngIf="view1" aria-label="Customer View">
    <div class="top mx-auto pt-3" style="max-width: 800px;">
        <div class="form-inline d-flex justify-content-center col-12">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="mt-1">search</mat-icon>
            <input class="form-control form-control-sm ml-3 w-75 mx-2" type="text" id="search_smartFlow"
                placeholder="Search" aria-label="Search" (input)="onKey($event)" [(ngModel)]="inputValue">
        </div>
    </div> 
    <!--<div class="top mx-auto pt-2" style="max-width: 800px;">
        <div class="form-inline d-flex justify-content-center col-12">
            <div class="form-check mx-2">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                    (change)="sortAZ()">
                <label class="form-check-label" for="flexRadioDefault1">
                    A-Z
                </label>
            </div>
            <div class="form-check mx-2">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked
                    (change)="sort10()">
                <label class="form-check-label" for="flexRadioDefault2">
                    1-0
                </label>
            </div>
        </div>
    </div>-->

    <div class="top mx-auto pt-2" style="max-width: 800px;">
        <div class="form-inline d-flex justify-content-center col-12">
            <button (click)="last()" class="btn btn-secondary mx-2" [disabled]="step == 0">Last</button>
            <div class="form-inline d-flex align-items-center mx-3">
                <h6>Showing {{step * step_size + 1}} - {{(step + 1) * step_size}} of {{toShow.length}} </h6>
            </div>
            <button (click)="next()" class="btn btn-secondary mx-2"
                [disabled]="((step + 1)  * step_size) >= toShow.length">Next</button>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <h6 class="mx-4 mt-2">Total Devices : {{totDevice}}</h6>
    </div>

    <div class="row content mx-auto my-4" style="max-width: 800px;">
        <div *ngIf="toShow.length == 0" class="row justify-content-center">
            <div class="spinner-border text-primary m-5" style="width: 6rem; height: 6rem;" role="status">
            </div>
        </div>
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [expanded]="true" class="example-headers-align"
                *ngFor="let customer of toShow | slice:(step * step_size ):((step + 1) * step_size); index as i;">
                <mat-expansion-panel-header class="d-flex justify-content-between">
                    <mat-panel-title>
                        <small [ngClass]="{'text-success':selected.customer_id_ == customer.customer_id_}">#{{step *
                            step_size + (i + 1)}} </small>
                    </mat-panel-title>
                    <mat-panel-description>
                        <h6 [innerHTML]="customer.customer"
                            [ngClass]="{'text-success':selected.customer_id_ == customer.customer_id_}"></h6>
                        <p class="mb-1" [ngClass]="{'text-success':selected.customer_id_ == customer.customer_id_}">{{customer.customer_id_}}</p>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <h6 [innerHTML]="customer.customer"></h6>
                    <div class="row">
                        <p class="mb-1">Devices : <strong>{{customer.___of_installed_customer_bases}}</strong></p>
                        <p class="mb-1">Country : <strong>{{customer.country}}</strong> </p>
                        <p class="mb-1">Vaki Representative : <strong>{{customer.vaki_representative__ == 0?
                            "NO":"YES"}}
                        </strong></p>
                    </div>
                    <div class="d-flex justify-content-between">
                        
                        <button (click)="getInfo(customer)" class="btn btn-success mt-2">Select</button>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>

<section *ngIf="view2" aria-label="Info View">
    <section aria-label="Service View">
        <!-- Devices to show this might be batter to but in a componet on it own-->>
        <div class="d-flex justify-content-center">
            <h4 class="mt-2">Devices</h4>
        </div>
        <div class="top mx-auto pt-1" style="max-width: 800px;">
            <div class="form-inline d-flex justify-content-center col-12">
                <mat-icon aria-hidden="false" aria-label="Example home icon" class="mt-1">search</mat-icon>
                <input class="form-control form-control-sm ml-3 w-75 mx-2" type="text" id="search_smartFlow"
                    placeholder="Search" aria-label="Search" (input)="onKeyDevice($event)"
                    [(ngModel)]="inputDeviceValue">
            </div>
        </div>

        <div class="top mx-auto pt-2" style="max-width: 800px;">
            <div class="form-inline d-flex justify-content-center col-12">
                <button (click)="lastDevice()" class="btn btn-secondary mx-2"
                    [disabled]="stepD == 0">Last</button>
                <div class="form-inline d-flex align-items-center mx-3">
                    <h6>Showing {{stepD * step_sizeD + 1}} - {{(stepD + 1) * step_sizeD}} of {{devicesToShow.length}}
                    </h6>
                </div>
                <button (click)="nextDevice()" class="btn btn-secondary mx-2"
                    [disabled]="((stepD + 1)  * step_sizeD) >= devicesToShow.length">Next</button>
            </div>
        </div>

        <div class="row content mx-auto my-4" style="max-width: 800px;">
            <div *ngIf="devices.length == 0 && selected.customer_id_ != -1" class="row justify-content-center">
                <div class="spinner-border text-primary m-5" style="width: 6rem; height: 6rem;" role="status">
                </div>
            </div>

            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel [expanded]="false" class="example-headers-align"
                    *ngFor="let device of devicesToShow | slice:(stepD * step_sizeD ):((stepD + 1) * step_sizeD); index as i;">
                    <mat-expansion-panel-header class="d-flex justify-content-between">
                        <mat-panel-title>
                            <small>#{{stepD * step_sizeD + (i + 1)}} : <!--<strong>{{getDate(device.delivery_date)}}</strong>--></small>
                        </mat-panel-title>
                        <mat-panel-description>
                            <h6 [ngClass]="{'text-primary':hasSmartFlow(device)}">{{device.model + " - " + device.type}}</h6>
                            <p class="mb-1">{{device.product_serial__}}</p>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <h6 [innerHTML]="device.product"></h6>
                        <div class="row">
                            <p class="mb-1">Product group : <strong>{{device.product_group}}</strong></p>
                            <p class="mb-1">Model : <strong>{{device.model}}</strong> </p>
                            <p class="mb-1">Product Serial : <strong>{{device.product_serial__}}</strong> </p>
                            <p class="mb-1">Delivery Date : <strong>{{getDate(device.delivery_date)}}</strong></p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <h6 *ngIf="hasSmartFlow(device)" class="mb-1">SmartFlow ID : <strong class="text-primary">{{device.smartflow_id}}</strong> </h6>
                            <button *ngIf="hasSmartFlow(device)"  class="btn btn-success"
                            [routerLink]="['/smartflows']" [queryParams]="{ id:device.smartflow_id, name: selected.customer + ' : ' + device.product + ' ' + device.product_serial__}">ADD</button>
                        </div>
                    </div> 
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </section>

    <section aria-label="Service View">
        <div class="d-flex justify-content-center">
            <h4 class="mt-2">Support</h4>
        </div>
        <div class="top mx-auto pt-3" style="max-width: 800px;">
            <div class="form-inline d-flex justify-content-center col-12">
                <mat-icon aria-hidden="false" aria-label="Example home icon" class="mt-1">search</mat-icon>
                <input class="form-control form-control-sm ml-3 w-75 mx-2" type="text" id="search_smartFlow"
                    placeholder="Search" aria-label="Search" (input)="onKeyService($event)"
                    [(ngModel)]="inputServiceValue">
            </div>
        </div>

        <div class="top mx-auto pt-2" style="max-width: 800px;">
            <div class="form-inline d-flex justify-content-center col-12">
                <button (click)="lastService()" class="btn btn-secondary mx-2"
                    [disabled]="stepS == 0">Last</button>
                <div class="form-inline d-flex align-items-center mx-3">
                    <h6>Showing {{stepS * step_sizeS + 1}} - {{(stepS + 1) * step_sizeS}} of {{servicesToShow.length}}
                    </h6>
                </div>
                <button (click)="nextService()" class="btn btn-secondary mx-2"
                    [disabled]="((stepS + 1)  * step_sizeS) >= servicesToShow.length">Next</button>
            </div>
        </div>

        <div class="row content mx-auto my-4" style="max-width: 800px;">
            <div *ngIf="!serviceSpinnerHide" class="row justify-content-center">
                <div class="spinner-border text-primary m-5" style="width: 6rem; height: 6rem;" role="status">
                </div>
            </div>
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel [expanded]="false" class="example-headers-align"
                    *ngFor="let service of servicesToShow | slice:(stepS * step_sizeS ):((stepS + 1) * step_sizeS); index as i;">
                    <mat-expansion-panel-header class="d-flex justify-content-between">
                        <mat-panel-title>
                            <small>#{{stepS * step_sizeS + (i + 1)}} : <strong>{{getDate(service.start_date)}}
                                </strong> </small>
                        </mat-panel-title>
                        <mat-panel-description>
                            <h6>{{service.product + " " + service.model}}</h6>
                            <p class="mb-1">S/N : {{service.product_serial__}}</p>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <h6 [innerHTML]="service.product"></h6>
                        <div class="row">
                            <p class="mb-1">Product group : <strong>{{service.product_group}}</strong></p>
                            <p class="mb-1">Model : <strong>{{service.model}}</strong> </p>
                            <p class="mb-1">Product Serial : <strong [innerHTML]="service.product_serial__"></strong>
                            </p>
                            <p class="mb-1">Start Date : <strong>{{getDate(service.start_date)}}</strong></p>
                            <p class="mb-1">Status : <strong [innerHTML]="service.work_order_status"></strong></p>
                            <p class="mb-1">Type of work order : <strong
                                    [innerHTML]="service.type_of_work_order"></strong></p>
                            <p class="mb-1">Priority : <strong [innerHTML]="service.priority"></strong></p>
                            <p class="mb-1">Comment : <strong [innerHTML]="service.comments"></strong></p>
                            <p class="mb-1">Summary : <strong [innerHTML]="service.summary"></strong></p>
                            <p class="mb-1">Instructions : <strong
                                    [innerHTML]="service.special_comments_instructions"></strong></p>

                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="mb-1">Creator : <strong [innerHTML]="service.record_owner"></strong></p>
                            <!--<button (click)="getServiceInfo(service)"
                                class="btn btn-outline-success m-2">Select</button>-->
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </section>
</section>