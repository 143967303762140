import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Device, TimeStamp } from 'src/app/models/Devices';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/util/hleper';
import { Location } from '@angular/common';
import { MyMessage } from 'src/app/classes/my-message';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent extends MyMessage {
  smartflow_id: string = '';
  name: string = '';
  
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  devices: Device[] = [];
  notes: any[] = [];

  device: Device = {
    type: '',
    id: '',
    update: { nanoseconds: 0, seconds: 0 },
    created: { nanoseconds: 0, seconds: 0 },
  };

  note: any = {
    title: '',
    message: '',
    submessage: '',
    creator: '',
    user_id: '',
    uid: '',
    create: '',
  };

  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    public auth: AuthService  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      if (params !== null && params !== undefined) {
        //We loading the player script on after view is loaded
        this.device.type =
          params['device_type'] === undefined ? '' : params['device_type'];
        this.device.id =
          params['device_id'] === undefined ? '' : params['device_id'];
        this.smartflow_id =
          params['smartflow_id'] === undefined ? '' : params['smartflow_id'];
        this.name =
          params['smartflow_name'] === undefined ? '' : params['smartflow_name'];

        this.auth.loggedIn.subscribe((loggedIn) => {
          console.log('Device component legedIn : ', loggedIn);
          if (loggedIn) {
            // If we are loaded in then we get the data.
            // Will use this on more places later
            if (this.device.id !== '' && this.smartflow_id.length === 6) {
              this.auth.fs
                .getNode(
                  'notes',
                  'uid',
                  this.smartflow_id + this.device.type + this.device.id
                )
                .pipe(takeUntil(this.destroyed$))
                .subscribe((a: any[]) => {
                  this.notes = a;
                });

              // Lets find the smartflow and get the devices
              let found = this.auth.fs.getSmartflow(this.smartflow_id);
              if (found !== undefined) {
                this.devices = found.devices;
              }
            }
          }
        });

        // Lets get info about the owner
      }
    });
  }

  getTime(time: TimeStamp) {
    return Helper.getTime(time);
  }

  getDevice() {
    const d = this.devices.find((d) => d.id === this.device.id);
    return d === undefined ? this.device : d;
  }

  backClicked() {
    this._location.back();
  }

  getImage(type: string) {
    return Helper.image[type];
  }

  onSubmit(ngForm: NgForm) {
    this.note.create = new Date().toDateString();
    this.note.creator = this.auth.globalData.user.displayName;
    // Later we will store devices in devices colleaction with uid.
    // This is just ugly temp
    this.note.uid = this.smartflow_id + this.device.type + this.device.id;
    this.note.user_id = this.auth.globalData.user.uid;
    this.auth.fs.addNode('notes', this.note).then(
      (res) => {
        // Send Varification link in email
        this.showMessage('success', 'Note added successfully.', 4000);
        //this.note.message = '';
        //this.note.title = '';
      },
      (err) => {
        this.showMessage('danger', 'Faild to add note.', 4000);
      }
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  delete(note_id: string) {
    console.log('Delete note: ', note_id);
    this.auth.fs.deleteNode('notes', note_id).then(
      (res) => {
        // Send Varification link in email
      },
      (err) => {
      }
    );
  }
}
