import { Component, OnInit } from '@angular/core';
import { FunctionsService } from 'src/app/services/functions/functions.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { QBCustomer, QBDevices, QBService } from '../../../util/quickbase/customer';
import { MyMessage } from 'src/app/classes/my-message';
import { GlobalDataService } from 'src/app/services/global-data.service';

@Component({
  selector: 'app-quickbase',
  templateUrl: './quickbase.component.html',
  styleUrls: ['./quickbase.component.css']
})
export class QuickbaseComponent extends MyMessage implements OnInit {
  toShow: Array<QBCustomer> = new Array();
  selected: QBCustomer = {
    country: '',
    customer: '',
    customer_id_: -1,
    update_id: '',
    vaki_representative__: 0,
    ___of_installed_customer_bases: 0
  };

  step: number = 0;
  step_size: number = 50;

  totDevice: number = 0

  view1: boolean = true;
  view2: boolean = false;
  view3: boolean = false;
  inputValue: string = "";

  // Device
  devices: Array<QBDevices> = new Array();
  devicesToShow: Array<QBDevices> = new Array();

  stepD: number = 0;
  step_sizeD: number = 50;

  inputDeviceValue: string = "";

  // Service
  services: Array<QBService> = new Array();
  servicesToShow: Array<QBService> = new Array();

  stepS: number = 0;
  step_sizeS: number = 50;

  inputServiceValue: string = "";
  serviceSpinnerHide: boolean = false;

  customerName = "";
  smartFlowID = "";

  constructor(private fs: FunctionsService, public globalData: GlobalDataService,) {
    super();
  }

  ngOnInit(): void {
    if (this.globalData.customers.length === 0) {
      this.fs.getAllQuickbaseUsersOnCall("").subscribe((data: any) => {
        if ('message' in data) {
          this.globalData.customers = data.message;
          // Sort customers by date
          this.processCustomersData();
        } else if ('error' in data) {
          // Here we can stop the spinner or show error
        }
      });
    } else {
      // We have alrady downloaded the customers from qb.
      this.processCustomersData();
    }
  }

  processCustomersData() {
    this.toShow = this.globalData.customers;
    this.getTotDevice(); // Get total devices
    this.sort10();
  }

  getTotDevice() {
    this.globalData.customers.forEach((customer: QBCustomer) => {
      this.totDevice += customer.___of_installed_customer_bases;
    });
  }

  sortAZ() {
    this.toShow.sort((a, b) => a.customer.localeCompare(b.customer));
  }

  sort10() {
    this.toShow.sort((a, b) => (a.___of_installed_customer_bases == b.___of_installed_customer_bases) ? 0 : ((a.___of_installed_customer_bases > b.___of_installed_customer_bases)) ? -1 : 1);
  }

  onKey(event: any) {
    this.inputValue = event.target.value;
    this.toShow = new Array();
    this.globalData.customers.forEach((customer: QBCustomer) => {
      if (!(!customer.customer.toLowerCase().includes(this.inputValue.toLowerCase()) && !customer.customer.toLowerCase().includes(this.inputValue.toLowerCase()))) {
        this.toShow.push(customer);
      }
    });
  }

  last() {
    if (this.step > 0) {
      this.step--;
    }
  }

  next() {
    if ((this.step * this.step_size + 1) < this.toShow.length) {
      this.step++;
    }
  }

  public view(i: number) {
    this.view1 = (i == 0);
    this.view2 = (i == 1);
    this.view3 = (i == 2);
  }

  clearAll() {
    this.step = this.stepD = this.stepS = 0;
  }

  getInfo(customer: QBCustomer) {
    this.selected = customer;
    this.clearAll();
    this.view(1)
    this.getDevicesFromQB();
    this.getServicesFromQB();
  }

  getDevicesFromQB() {
    this.devices = new Array();
    this.devicesToShow = new Array();

    this.fs.getAllQuickbaseUsers(this.decodeHtmlCharCodes(this.selected.customer)).subscribe((data: any) => {
      if ('message' in data) {
        this.devices = data.message;
        if (this.devices instanceof Array) {
          // this.cleanUpDevicesList();
          this.devicesToShow = this.devices;
          this.sort10Device();
        } else {
          this.devicesToShow.push(this.devices);
        }
      } else if ('error' in data) {
        // Here we can stop the spinner or show error
        console.log("Error in getting all getAllQuickbaseUsers()")
      }
    });
  }

  // Service
  getServicesFromQB() {
    this.serviceSpinnerHide = false;
    this.services = new Array();
    this.servicesToShow = new Array();
    this.fs.getQucikBaseCustomerServices(this.decodeHtmlCharCodes(this.selected.customer)).subscribe((data: any) => {
      if ('message' in data) {
        this.services = data.message;
        if (this.services instanceof Array) {
          // this.cleanUpDevicesList();
          this.servicesToShow = this.services;
          this.sort10Service();
        } else {
          this.servicesToShow.push(this.services);
        }
      } else if ('error' in data) {
        // Here we can stop the spinner or show error
        console.log("Error in getting all getQucikBaseCustomerServices()")
      }
      this.serviceSpinnerHide = true;
    });
  }

  decodeHtmlCharCodes(str: string) {
    return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
      let trim = match.split("&#").join("");
      trim = trim.split(";").join("")
      return "%" + Number(trim).toString(16).toUpperCase();
    }).split(" ").join("%20");
  }

  getDateNumber(qbDate: number) {
    var dateObj = new Date(qbDate);
    var m = dateObj.getUTCMonth() + 1; //months from 1-12
    var d = dateObj.getUTCDate();
    var y = dateObj.getUTCFullYear();
    return parseInt(y + "" + m + "" + d);
  }

  getDate(qbDate: number) {
    var dateObj = new Date(qbDate);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    return day + "/" + month + "/" + year;
  }

  // Devices
  onKeyDevice(event: any) {
    this.inputDeviceValue = event.target.value;
    this.devicesToShow = new Array();
    this.devices.forEach((device: QBDevices) => {
      if (!(!device.product_serial__.toLowerCase().includes(this.inputDeviceValue.toLowerCase())
        && !device.product.toLowerCase().includes(this.inputDeviceValue.toLowerCase())
        && !device.model.toLowerCase().includes(this.inputDeviceValue.toLowerCase())
        && !this.getDate(device.delivery_date).includes(this.inputDeviceValue.toLowerCase()))) {
        this.devicesToShow.push(device);
      }
    });
  }

  cleanUpDevicesList() {
    const devicesTemp = this.devices;
    this.devices = new Array();
    devicesTemp.forEach(d => {
      // Laga þetta seinna TODO
      if (d.product_serial__ != undefined && d.product != undefined && d.product_serial__ != "" && d.product != "") {
        if (d.product) {
          if ((d.product + "").includes("Counter")) {
            console.log(d.product + " SmartFlow ID : " + d.smartflow_id);
          }
        }
        this.devices.push(d);
      }
    })
  }

  sortAZDevice() {
    this.devicesToShow.sort((a, b) => a.product.localeCompare(b.product));
  }

  sort10Device() {
    this.devicesToShow.sort((a, b) => (this.getDateNumber(a.delivery_date) == this.getDateNumber(b.delivery_date)) ? 0 : ((this.getDateNumber(a.delivery_date) > this.getDateNumber(b.delivery_date))) ? -1 : 1);
  }

  lastDevice() {
    if (this.stepD > 0) {
      this.stepD--;
    }
  }

  nextDevice() {
    if ((this.stepD * this.step_sizeD + 1) < this.devicesToShow.length) {
      this.stepD++;
    }
  }

  addSmartFlow(device: QBDevices) {
    this.customerName = this.selected.customer + " : " + device.product_serial__;
    this.smartFlowID = device.smartflow_id;
  }

  hasSmartFlow(device: QBDevices) {
    return device.smartflow_id !== undefined && device.smartflow_id !== "";
  }


  onKeyService(event: any) {
    this.inputServiceValue = event.target.value;
    this.servicesToShow = new Array();
    this.services.forEach((service: QBService) => {
      if (!(!service.model.toLowerCase().includes(this.inputServiceValue.toLowerCase())
        && !service.product.includes(this.inputServiceValue.toLowerCase())
        && !service.product_group.includes(this.inputServiceValue.toLowerCase())
        && !service.product_serial__.includes(this.inputServiceValue.toLowerCase())
      )) {
        this.servicesToShow.push(service);
      }
    });
  }

  sort10Service() {
    this.servicesToShow.sort((a, b) => (a.update_id == b.update_id) ? 0 : ((a.update_id > b.update_id)) ? -1 : 1);
  }

  lastService() {
    if (this.stepS > 0) {
      this.stepS--;
    }
  }

  nextService() {
    if ((this.stepS * this.step_sizeS + 1) < this.servicesToShow.length) {
      this.stepS++;
    }
  }

  getServiceInfo(service: QBService) {
    console.log(service);
  }
}
