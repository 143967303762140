<div class="card shadow border-start-primary w-100" [ngClass]="smartflow.grader.data.auto ? 'border-success text-success' :''">
    <div [ngClass]="(smartflow.grader.offline || smartflow.grader.data.name === 'null'? 'bg-secondary text-white' : 'text-success')"  class="card-header text-center">
        <h6 class="fw-bold m-0">{{smartflow.grader.data.name ==='null'? "Grader Disconnected": smartflow.grader.data.name}}</h6>
    </div>
    <div class="card-body">
        <section class="row align-items-center">
            <div class="col">
                <div class="text-uppercase text-primary fw-bold text-xs"><span>Small</span></div>
            </div>
            <div class="col-8">
                <div class="text-dark h5 mb-0 text-center"><span style="font-size: 36px;">&lt;
                        {{smartflow.grader.data.small.toFixed(1)}}</span></div>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="row align-items-center">
            <div class="col">
                <div class="text-uppercase text-primary fw-bold text-xs"><span>Medium</span></div>
            </div>
            <div class="col-8">
                <div class="text-dark h5 mb-0 text-center"><span style="font-size: 36px;">&lt;
                        {{smartflow.grader.data.medium.toFixed(1)}}</span></div>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="row align-items-center">
            <div class="col">
                <div class="text-uppercase text-primary fw-bold text-xs"><span>Large</span></div>
            </div>
            <div class="col-8">
                <div class="text-dark h5 mb-0 text-center"><span style="font-size: 36px;">&lt;
                        {{smartflow.grader.data.large.toFixed(1)}}</span></div>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="row align-items-center">
            <div class="col">
                <div class="text-uppercase text-primary fw-bold text-xs"><span>XL</span></div>
            </div>
            <div class="col-8">
                <div class="text-dark h5 mb-0 text-center"><span style="font-size: 36px;">&gt;
                        {{smartflow.grader.data.large.toFixed(1)}}</span></div>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="row align-items-center">
            <div class="col me-2 ">
                <div class="text-uppercase text-primary text-xs mb-1"><span>SPEED</span></div>
                <div class="text-dark h5 mb-0"><span style="font-size: 36px;">{{speed[smartflow.grader.data.speed]}}
                        RPM</span></div>
            </div>
            <div class="col me-4">
                <div class="text-uppercase text-primary text-xs mb-1 text-center"><span>Status</span></div>
                <div class="text-dark h5 text-center"><span style="font-size: 36px;"
                        [ngClass]="(smartflow.grader.data.on ? 'text-success' : 'text-danger')">
                        {{smartflow.grader.data.auto ?"AUTO": smartflow.grader.data.on?"ON":"OFF"}}</span>
                </div>
            </div>
        </section>
    </div>
</div>