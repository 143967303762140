import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { SmartFlow, SmartFlowInfo } from 'src/app/models/User';
import { Helper, MyArray } from 'src/app/util/hleper';
import { FirebaseService } from '../firebase/firebase.service';
import { increment } from '@angular/fire/firestore';
import { GlobalDataService } from '../global-data.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  clientDoc!: AngularFirestoreDocument;

  constructor(
    private afs: AngularFirestore,
    private sfService: FirebaseService,
    private storage: AngularFireStorage,
    public globalData: GlobalDataService
  ) { }

  public getSmartflow(id: string) {
    return this.globalData.usersSmartFlows.all().find((s) => s.info.id === id);
  }

  loggedIn(uid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.destroyed = new ReplaySubject(1);
      this.getUserSmartFlow(uid)
        .pipe(takeUntil(this.destroyed))
        .subscribe((data) => {
          if (data !== null && data !== undefined) {
            // New data or data change. Clear array and rebuild
            this.globalData.usersSmartFlows.clear();

            data.forEach((element: SmartFlowInfo, index: number) => {
              // sfInfo is the info the user has added to this smartflow
              const sfInfo = element;
              const i = index;
              // Get smartflows in DB
              this.getSmartFlowInfo(sfInfo.id)
                .pipe(take(1))
                .subscribe((info) => {
                  let smartflow: SmartFlow;
                  if (info !== null && info !== undefined) {
                    smartflow = info as SmartFlow;
                    smartflow.info = sfInfo;
                    // This has to happen before we add it.
                    // If not we might try to get devices and we
                    // get undefined
                    this.addDviceInfoToSmartFlow(smartflow);
                  } else {
                    // Its an old smartlfow, there is no more info about it.
                    // Like VAKI86
                    smartflow = Helper.createSmartFlowWidthInfo(sfInfo);
                  }

                  const index = this.globalData.usersSmartFlows.all().findIndex(e => e.info.id === smartflow.info.id);

                  if (index === -1) {
                    this.globalData.usersSmartFlows.push(smartflow);
                    // Always sort them afterwords
                    this.globalData.usersSmartFlows.all().sort((a, b) => a.info.name.localeCompare(b.info.name));
                  } else {
                    this.globalData.usersSmartFlows.all()[index] = smartflow;
                  }

                  if (i + 1 >= data.length) {
                    // last item lets notify anyone listening to usersSmartFlows
                    this.globalData.usersSmartFlows.notify();
                    // Will use this to replace notify();
                    resolve();
                  }

                  // We should move this to the smartflows.component
                  this.sfService.link(
                    smartflow,
                    'updateTime',
                    sfInfo.id + '/SmartFlow/online',
                    this.destroyed
                  );

                  this.sfService.link(
                    smartflow,
                    'session_on',
                    sfInfo.id + '/counter/on',
                    this.destroyed
                  );
                });
            });
          } else {
            reject();
          }
        });
    });
  }

  isAdmin(id: string) {
    // Here we have to find out if the user is admin
    this.clientDoc = this.afs.doc(`admins/${id}`);
    return this.clientDoc
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((action) => {
          return action.payload.data();
        })
      );
  }

  updateUserData(uid: string, user: any): Promise<void> {
    return this.afs.collection('users').doc(uid).set(user, { merge: true });
  }

  getSmartFlowInfo(id: string): Observable<any> {
    this.clientDoc = this.afs.doc(`smartflows/${id}`);
    return this.clientDoc
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((action) => {
          return action.payload.data();
        })
      );
  }

  getUsers() {
    return this.afs
      .collection('users')
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            return {
              data: action.payload.doc.data(),
              id: action.payload.doc.id,
            };
          });
        })
      );
  }

  getCompanies(user_id: string) {
    return this.afs
      .collection('/company', (ref) => ref.where('user_id', '==', user_id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            return {
              data: action.payload.doc.data(),
              id: action.payload.doc.id,
            };
          });
        })
      );
  }

  getUserInfo(user_id: string): Observable<any> {
    return this.afs
      .collection('users')
      .doc(user_id)
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((action) => {
          return action.payload.data();
        })
      );
  }

  getUserSmartFlow(id: string): Observable<SmartFlowInfo[]> {
    // Get smartflows user has added
    return this.afs
      .collection('users')
      .doc(id)
      .collection('smartflows')
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            const data = Helper.getEmptySmartFlowInfo();
            Helper.copyInvert(
              data,
              action.payload.doc.data().smartflow as SmartFlowInfo
            );
            data.docID = action.payload.doc.id;
            return data;
          });
        })
      );
  }


  getSmartFlowDevice(id: string, device: string): Observable<any[]> {
    // Get clients with the id
    return this.afs
      .collection('smartflows')
      .doc(id)
      .collection(device)
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            let temp = action.payload.doc.data();
            temp['id'] = action.payload.doc.id;
            temp['type'] = device;
            return temp;
          });
        })
      );
  }

  addDviceInfoToSmartFlow(smartflow: SmartFlow) {
    const local_id = smartflow.info.id;
    smartflow.devices = [];

    ['Counter', 'SmartGrader', 'Pump', 'DC'].forEach((device: string) => {
      // I make this const to make sure it dose not get over writen in
      // next ituration.
      this.getSmartFlowDevice(local_id, device)
        .pipe(take(1)) // For now we do take one. Leter we do this batter
        // where we will clear all pumps and then add pumps.
        .subscribe((data) => {
          if (data !== null && data !== undefined) {
            // New data or data change. Clear array and rebuild
            if (data !== null && data !== undefined && data.length > 0) {
              // Add all of the devices to the list.
              data.forEach((d) => {
                smartflow.devices.push(d);
              });
            }
          }
        });
    });
  }

  logout() {
    this.destroyed.next(true);
    this.destroyed.complete();
    this.globalData.usersSmartFlows.clear();
  }

  updateSmartFlow(user_id: string, smartFlowInfo: SmartFlowInfo) {
    let ref = this.afs
      .collection('users')
      .doc(user_id)
      .collection('smartflows')
      .doc(smartFlowInfo.docID);
    return ref.update({ smartflow: smartFlowInfo });
  }

  addConnectionToSmartFlow(sf_id: string) {
    let ref = this.afs
      .collection('smartflows')
      .doc(sf_id)
    return ref.update({ count: increment(1) });
  }

  addSmartFlowIfExist(
    user_id: string,
    smartflow: SmartFlowInfo
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.sfService
        .getSmartFlow(smartflow.id + '/SmartFlow')
        .valueChanges()
        .pipe(take(1)) //take is read once or 1
        .subscribe((data: null | undefined) => {
          if (data === null || data === undefined) {
            // No smartflow
            return reject();
          } else {
            // SmartFlow exist lets dos soem work

            this.getUserInfo(user_id)
              .pipe(take(1))
              .subscribe((info) => {
                if (info === null || info === undefined) {
                  return reject();
                }
                let ref = this.afs.collection('users').doc(user_id);

                if (info.smartflows === undefined) {
                  // We add this here. This is for firebase rulse.
                  // If the id is in smartlfows then we can read its info.
                  ref.update({ smartflows: [smartflow.id] }); // Array, used for rules
                  ref.collection('smartflows').add({ smartflow });
                } else {
                  if (!info.smartflows.includes(smartflow.id)) {
                    info.smartflows.push(smartflow.id);
                    ref.update({ smartflows: info.smartflows });
                    // This could result in an error.
                    // If the item is only in the list but not in the
                    // smartlfows array.
                    ref.collection('smartflows').add({ smartflow });
                  }
                }
                resolve(true);
              });
          }
        });
    });
  }

  removeSmartFlow(user_id: string, docID: string, smartflow_id: string) {
    this.getUserInfo(user_id)
      .pipe(take(1))
      .subscribe((info) => {
        let ref = this.afs.collection('users').doc(user_id);

        if (info.smartflows === undefined) {
          // No smartflows to delete
        } else {
          if (info.smartflows.includes(smartflow_id)) {
            // Remove the id from the array
            const newArr = info.smartflows.filter((ele: string) => {
              return ele != smartflow_id;
            });
            // Update the array
            ref.update({ smartflows: newArr });
            // This could result in an error.
            // If the item is only in the list but not in the
            // smartlfows array.
            ref.collection('smartflows').doc(docID).delete();
          }
        }
      });
  }

  deleteDevice(
    smartlfow_id: string,
    device_id: string,
    device_type: string
  ): Promise<void> {
    return this.afs
      .collection('smartflows')
      .doc(smartlfow_id)
      .collection(device_type)
      .doc(device_id)
      .delete();
  }

  getSessions(smartflow_id: string) {
    return this.afs
      .collection('/sessions', (ref) => ref.where('owner', '==', smartflow_id))
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            return {
              data: action.payload.doc.data(),
              id: action.payload.doc.id,
            };
          });
        })
      );
  }

  getSession(id: string) {
    return this.afs
      .collection('/sessions/' + id + '/data', (ref) => ref.orderBy('sort', 'asc'))
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            return {
              data: action.payload.doc.data(),
              id: action.payload.doc.id,
            };
          });
        })
      );
  }

  getUserData(id: string) {
    // Here we have to find out if the user is admin
    this.clientDoc = this.afs.doc(`users/${id}`);
    return this.clientDoc
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((action) => {
          return action.payload.data();
        })
      );
  }

  getAllSmartFlows() {
    return this.afs
      .collection('smartflows')
      .snapshotChanges()
      .pipe(takeUntil(this.destroyed))
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            return {
              data: action.payload.doc.data(),
              id: action.payload.doc.id,
            };
          });
        })
      );
  }


  getNode(node: string, key: string, val: string): any {
    const ref = this.afs.collection('/' + node, (ref) =>
      ref.where(key, '==', val)
    );
    return ref
      .snapshotChanges()
      .pipe(
        map((changes: any[]) => {
          return changes.map((action) => {
            return {
              data: action.payload.doc.data(),
              id: action.payload.doc.id,
            };
          });
        })
      );
  }

  addNode(node: string, obj: any) {
    let ref = this.afs.collection(node);
    return ref.add(obj);
  }

  deleteNode(node: string, doc: string) {
    return this.afs.collection(node).doc(doc).delete();
  }

  updateNode(node: string, doc: string, obj: any) {
    let ref = this.afs.collection(node).doc(doc);
    return ref.update(obj);
  }

  async uploadFile(id: string, file: File): Promise<any> {
    try {
      await this.storage.ref('users/' + id).child(file.name).put(file);
      return this.storage.ref(`users/${id}/${file.name}`).getDownloadURL().toPromise();
    } catch (error) {
      console.log(error);
    }
  }
}