<div class="row content">
    <div class="d-flex justify-content-between">
        <h2 class="mx-4 my-2">SmartFlow <span class="text-primary">{{this.smartflowInfo.name}}</span></h2>
        <button mat-mini-fab color="primary" class="mb-3" aria-label="Back" [routerLink]="['/smartflows']">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
</div>

<div class="top mx-auto p-4" style="max-width: 800px;">
    <mat-accordion class="example-headers-align mb-4 mx-auto" style="max-width: 600px;">
        <mat-expansion-panel [expanded]="true" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h6 class="mt-2">ID : {{this.smartflowInfo.id}}</h6>
                </mat-panel-title>
                <mat-panel-description>
                    <p></p>
                    <mat-icon>edit</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form #clientForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="d-flex justify-content-between">
                    <mat-form-field class="mat-two">
                        <mat-label>Name</mat-label>
                        <input matInput name="name" #addedName="ngModel" value="{{this.smartflowInfo.name}}"
                            [ngClass]="{'is-invalid':addedName.errors && addedName.touched}"
                            [(ngModel)]="this.smartflowInfo.name" minlength="2" required>
                    </mat-form-field>
                    <mat-form-field class="mat-two">
                        <mat-label>Company</mat-label>
                        <input matInput (cdkAutofill)="companyAutofilled = $event.isAutofilled"
                            [(ngModel)]="this.smartflowInfo.company" name="company">
                        <mat-hint *ngIf="companyAutofilled">Autofilled!</mat-hint>
                    </mat-form-field>
                </div>
                <div class="d-flex justify-content-between">
                    <mat-form-field class="mat-three">
                        <mat-label>Farm</mat-label>
                        <input matInput name="farm" [(ngModel)]="this.smartflowInfo.farm" name="farm">
                    </mat-form-field>

                    <mat-form-field class="mat-three">
                        <mat-label>Site</mat-label>
                        <input matInput name="site" [(ngModel)]="this.smartflowInfo.site" name="site">
                    </mat-form-field>

                    <mat-form-field class="mat-three">
                        <mat-label>Room</mat-label>
                        <input matInput name="room" [(ngModel)]="this.smartflowInfo.room" name="room">
                    </mat-form-field>
                </div>
                <div class="d-flex justify-content-between">
                    <mat-form-field class="mat-three">
                        <mat-label>Country</mat-label>
                        <input matInput (cdkAutofill)="countryAutofilled = $event.isAutofilled"
                            [(ngModel)]="this.smartflowInfo.country" name="country">
                        <mat-hint *ngIf="countryAutofilled">Autofilled!</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="mat-three">
                        <mat-label>City</mat-label>
                        <input matInput (cdkAutofill)="cityAutofilled = $event.isAutofilled"
                            [(ngModel)]="this.smartflowInfo.city" name="city">
                        <mat-hint *ngIf="cityAutofilled">Autofilled!</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="mat-three">
                        <mat-label>Address</mat-label>
                        <input matInput (cdkAutofill)="addressAutofilled = $event.isAutofilled"
                            [(ngModel)]="this.smartflowInfo.address" name="address">
                        <mat-hint *ngIf="addressAutofilled">Autofilled!</mat-hint>
                    </mat-form-field>
                </div>
                <div class="d-flex">
                    <mat-form-field class="w-100">
                        <mat-label>Description</mat-label>
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5" [(ngModel)]="this.smartflowInfo.description"
                            name="description"></textarea>
                    </mat-form-field>
                </div>
                <button type="submit" [disabled]="!(clientForm.valid && clientForm.dirty)" mat-raised-button
                    color="primary" class="w-100">SAVE</button>

            </form>
        </mat-expansion-panel>
        <div *ngIf="message" class="alert mt-4" [ngClass]="message_type" role="alert">
            {{errorMessage}}
        </div>
    </mat-accordion>
</div>
<mat-divider></mat-divider>
<div class="container">
    <mat-card class="example-card my-3 mx-auto" *ngFor="let device of devices; index as i">
        <mat-card-header>
            <mat-card-title>{{i + 1}} : {{device.type}}</mat-card-title>
            <mat-card-subtitle>{{device.id}}</mat-card-subtitle>
        </mat-card-header>
        <div>
            <img mat-card-image src={{getNewImage(device)}}>
        </div>
        <mat-card-content>
            <div *ngIf="device.type === 'Counter'">
                <!--<p><span class="text-secondary"> Company : {{$any(device).company}}</span></p>-->
                <p><span class="text-secondary"> Version : {{$any(device).version}}</span></p>
                <p><span class="text-secondary"> Channles : {{$any(device).nr_ch}}</span></p>
                <p><span class="text-secondary"> Teamviwer : {{$any(device).teamviwer}}</span></p>
                <p><span class="text-secondary"> Created : {{getTime(device.created)}}</span></p>
                <p><span class="text-secondary"> Login : {{getTime(device.update)}}</span></p>
            </div>
            <div *ngIf="device.type === 'Pump'">
                <p><span class="text-secondary"> Size : {{$any(device)['size']}}&rdquo;</span></p>
                <p><span class="text-secondary"> Speed average : {{$any(device)["Speed average"]}} %</span></p>
                <p *ngIf="$any(device)['size'] > 6"><span class="text-secondary"> Thermal state average :
                        {{$any(device)["Thermal state average"]}} %</span></p>

                <p><span class="text-secondary"> Main runtime : {{$any(device)['Main runtime']}} H</span></p>
                <p *ngIf="$any(device)['size'] > 6"><span class="text-secondary"> Main current average :
                        {{$any(device)["Main current average"]}} A</span></p>

                <p><span class="text-secondary"> Primer runtime : {{$any(device)['Primer runtime']}} H</span></p>
                <p *ngIf="$any(device)['size'] > 6"><span class="text-secondary"> Primer current average :
                        {{$any(device)["Primer current average"]}} A</span></p>
                <p><span class="text-secondary"> Created : {{getTime(device.created)}}</span></p>
                <p><span class="text-secondary"> Login : {{getTime(device.update)}}</span></p>
            </div>
            <div *ngIf="device.type === 'SmartGrader'">
                <p><span class="text-secondary"> Runtime : {{$any(device)['rt']}} H</span></p>
                <p><span class="text-secondary"> Angle : {{($any(device)["Angle"]*0.00249 + 1.2).toFixed(1)}}
                        &#176;</span></p>
                <p><span class="text-secondary"> Current average : {{$any(device)["Current average"]}} A</span></p>
                <p><span class="text-secondary"> Speed average : {{$any(device)['Speed average']*6 + 12}} rpm</span>
                </p>

                <p><span class="text-secondary"> Created : {{getTime(device.created)}}</span></p>
                <p><span class="text-secondary"> Login : {{getTime(device.update)}}</span></p>
            </div>
            <div *ngIf="device.type === 'DC'">
                <p><span class="text-secondary"> Created : {{getTime(device.created)}}</span></p>
                <p><span class="text-secondary"> Login : {{getTime(device.update)}}</span></p>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-stroked-button color="primary" [routerLink]="['/smartflows/smartflow/device']"
                [queryParams]="{ device_id:device.id, device_type:device.type, smartflow_id:smartflowInfo.id, smartflow_name:smartflowInfo.name}">View</button>
            <button *ngIf="auth.globalData.admin" mat-stroked-button color="warn"
                (click)="deleteDialog(device)">Delete</button>
        </mat-card-actions>
    </mat-card>

</div>