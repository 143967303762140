import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { delay } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SmartFlow Web App';
  IS_COOKIE_ALLOWED = 'IS_COOKIE_ALLOWED'

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  constructor(private titleService: Title,
    private observer: BreakpointObserver) {
    this.titleService.setTitle("SmartFlow");
    console.log(window.location.hostname)
    if (window.location.hostname.includes("vaki-smartflow")) {
      window.location.href = "https://smartflow.is/";
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
  }

  ngOnDestroy() {
  }

}
