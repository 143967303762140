import { Injectable } from '@angular/core';
import { SmartFlow } from '../../classes/smartflow';
import { ReplaySubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private allLinkSubscrip: Subscription[] = []; // This is for connection on 
  private smartflow: SmartFlow = new SmartFlow();

  constructor(private db: AngularFireDatabase) {
    // This is for testing
    // This could be any observable
    const that = this;
  }

  connect(id: string): Promise<void> {
    this.smartflow.id = id;
    var promise = new Promise<void>((resolve, reject) => {
      console.log('Connect to ', id);
      this.getSmartFlow(id + '/SmartFlow')
        .valueChanges()
        .pipe(take(1)) //take is read once or 1
        .subscribe((data) => {
          if (data === null || data === undefined) {
            // No data
            this.smartflow.setConnected(false);
            reject();
          } else {
            // We just connected to smartflow with id
            // Lets link all object to firebase object
            // this.linkAllDevices();
            resolve();
          }
        });
    });
    return promise;
  }

  public linkAllDevices() {
    if (!this.smartflow.connected.value) {
      this.connectCounter();
      this.connectPump();
      this.connectGrader();
      this.connectDensity();
      this.linkAll(this.smartflow.smartflow.data, this.smartflow.id + '/SmartFlow');
    }
  }

  // Here we init the connection for the counter.
  connectCounter() {
    this.smartflow.counter.connected = false; // Initialize connected as false
    const subscription = this.getSmartFlow(this.smartflow.id + '/counter')
      .valueChanges()
      // Assuming continuous listening
      .subscribe((data) => {
        if (data === null || data === undefined) {
          console.log("Counter is offline!");
          this.smartflow.counter.offline = true; // Mark as offline
        } else {
          this.smartflow.counter.offline = false; // Mark as online
          if (!this.smartflow.counter.connected) {
            this.smartflow.counter.connected = true;
            this.linkAll(this.smartflow.counter.data, this.smartflow.id + '/counter');
            this.smartflow.counter.startCollecting();
          }
        }
      });
  
    // Store the subscription for later cleanup
    this.allLinkSubscrip.push(subscription);
  }

  // Here we init the connection for the counter.
  connectGrader() {
    this.smartflow.grader.connected = false; // Initialize connected as false
    const subscription = this.getSmartFlow(this.smartflow.id + '/grader')
      .valueChanges()
      // Assuming continuous listening
      .subscribe((data) => {
        if (data === null || data === undefined) {
          console.log("Grader is offline!");
          this.smartflow.grader.offline = true; // Mark as offline
        } else {
          this.smartflow.grader.offline = false; // Mark as online
          if (!this.smartflow.grader.connected) {
            this.smartflow.grader.connected = true;
            this.linkAll(this.smartflow.grader.data, this.smartflow.id + '/grader');
            this.smartflow.grader.startCollecting();
          }
        }
      });
  
    // Store the subscription for later cleanup
    this.allLinkSubscrip.push(subscription);
  }


  // Here we init the connection for the counter.
  // We start data collection
  connectPump() {
    this.smartflow.pump.connected = false;
    const subscription = this.getSmartFlow(this.smartflow.id + '/pump')
      .valueChanges()
      // Removed take(1) here for continuous listening
      .subscribe((data) => {
        if (data === null || data === undefined) {
          console.log("Pump is offline!");
          this.smartflow.pump.offline = true;
        } else {
          this.smartflow.pump.offline = false;
          if (!this.smartflow.pump.connected) {
            this.smartflow.pump.connected = true;
            this.linkAll(this.smartflow.pump.data, this.smartflow.id + '/pump');
            this.smartflow.pump.startCollecting();
          }
        }
      });

    // Store the subscription for later cleanup
    this.allLinkSubscrip.push(subscription);
  }

  // Here we init the connection for the counter.
  // We start data collection
  connectDensity() {
    this.getSmartFlow(this.smartflow.id + '/dc')
      .valueChanges()
      .pipe(take(1)) //Taks is read once or 1
      .subscribe((data) => {
        if (data === null || data === undefined) {
          // No data do nothing
        } else {
          // We just connected to a counter under smartflow id
          this.linkAll(this.smartflow.dc.data, this.smartflow.id + '/dc');
          this.smartflow.dc.startCollecting();
        }
      });
  }

  readDataOnce(id: string, device: string) {
    this.getSmartFlow(id + '/' + device)
      .valueChanges()
      .pipe(take(1)) //Taks is read once or 1
      .subscribe((data) => {
        if (data === null || data === undefined) {
          // No data
          console.log('No data');
        } else {
          console.log(data);
        }
      });
  }

  getSmartFlowClass() {
    return this.smartflow;
  }

  public getSmartFlow(id: string) {
    return <AngularFireObject<any>>this.db.object('smartflow/' + id);
  }

  // Here we break the connection to firebase
  // and clear everthing
  discunnect() {
    console.log('discunnect()');

    this.allLinkSubscrip.forEach(function (value) {
      value.unsubscribe();
    });

    this.smartflow.reset();
  }

  // Here we link all keys of an object with
  // the same firebase object
  linkAll(source: { [x: string]: any }, path: string) {
    for (var key in source) {
      if (typeof source[key] === 'object') {
        this.linkAll(source[key], path + '/' + key);
      } else {

        const constKey = key;
        const ref = this.getSmartFlow(path + '/' + key);

        this.allLinkSubscrip.push(
          ref.valueChanges().subscribe((data) => {
            if (data === null || data === undefined) {
              // No data
            } else {
              // We have data
              source[constKey] = data;
            }
          })
        );

      }
    }
  }

  link(source: { [x: string]: any }, key: string, path: string, destroyed: ReplaySubject<boolean>) {
    const ref = this.getSmartFlow(path);
    ref.valueChanges().pipe(takeUntil(destroyed)).subscribe((data) => {
      if (data === null || data === undefined) {
        // No data
      } else {
        // We have data
        source[key] = data;
      }
    })
  }

  // Be carfull with this, this link will not be removed until application is closed
  linkForGood(source: { [x: string]: any }, key: string, path: string) {
    const ref = this.getSmartFlow(path);
    ref.valueChanges().subscribe((data) => {
      if (data === null || data === undefined) {
        // No data
      } else {
        // We have data
        source[key] = data;
      }
    })
  }
}
