import { Component, Input, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SmartFlow } from 'src/app/classes/smartflow';
import { ChartModle } from '../chart-model';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-counter-chart',
  templateUrl: './counter-chart.component.html',
  styleUrls: ['./counter-chart.component.css']
})
// This class is independent but works if smartflow and counter are running
// Its just for update chart 
export class CounterChartComponent extends ChartModle {

  showChart: boolean = true;

  @Input()
  smartflow: SmartFlow = new SmartFlow();

  @ViewChild(BaseChartDirective)
  chart: BaseChartDirective | undefined;


  reloadChartWithNewOptions() {
    this.showChart = false;
    this.options = { ...this.options, /* your new settings here */ };
    setTimeout(() => {
      this.showChart = true;
    }, 0);
  }

  updateCharts(): void {
    if (this.chart !== undefined) {
      this.chart.update();
    }
  }

  init(): void {
    // First get all the data paints form counter fishArr
    this.data.datasets = [
      {
        label: 'Channel 1',
        borderColor: 'rgba(255, 99, 132, 1)',
        data: [], tension: 0.1
      },
      {
        label: 'Channel 2',
        borderColor: 'rgba(54, 162, 235, 1)',
        data: [], tension: 0.1
      },
      {
        label: 'Channel 3',
        borderColor: 'rgba(255, 206, 86, 1)',
        data: [], tension: 0.1
      },
      {
        label: 'Channel 4',
        borderColor: 'rgba(153, 102, 255, 1)',
        data: [], tension: 0.1
      },
    ];

    this.options.plugins!.title!.text = this.smartflow.counter.data.name;

    // Here we listen to the counter subject if it changes we get datapoint
    this.smartflow.counter
      .getSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((p) => {
        if (p.reset) {
          this.clearGraph();
        } else {
          this.addDataPoint(p);
          this.updateCharts();
        }
      });

    this.smartflow.connected.pipe(takeUntil(this.destroyed$)).subscribe((b) => {
      // Listen for smartflow connect. If it is connected
      // we read counter labels.
      if (b) {
        setTimeout(() => {
          this.data.datasets.forEach(l => {
            // clear all labels
            l.label = '';
          })
          // Wait just in case we get delay in data.
          for (let i = 0; i < this.smartflow.counter.data.nr;) {
            this.data.datasets[i].label = this.smartflow.counter.get(
              'channel' + ++i
            ).name;
          }

          this.addAllDataPoints();
        }, 1000);
      } else {
        for (let i = 0; i < 4;) {
          this.data.datasets[i].label = 'Channel ' + ++i;
        }
      }
    });

    this.settings.update.pipe(takeUntil(this.destroyed$)).subscribe((b) => {
      // Settings changesed lets update if needed
      if (b) {
        // If its false then we update y-axes, later use number for more updates options
        this.updateMax(this.settings.settings.counter_scale);
        this.updateCharts();
        this.reloadChartWithNewOptions();
      }
    });
  }

  addAllDataPoints() {
    if (this.smartflow.connected.value) {
      this.clearGraph();
      this.smartflow.counter.getDataPoints().forEach((p) => {
        this.addDataPoint(p);
      });
      this.updateCharts();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  constructor() {
    super();
  }
}