import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { MyMessage } from 'src/app/classes/my-message';
import { SmartFlow } from 'src/app/classes/smartflow';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { Helper } from 'src/app/util/hleper';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css']
})
export class ConnectComponent extends MyMessage implements OnInit {
  @Input()
  smartflow: SmartFlow = new SmartFlow();

  // Define the options for the dropdowns
  chartIntervals: string[] = ['15 minutes', '30 minutes', '1 hour', '4 hours', '8 hours', '12 hours'];
  chartIntervalsNumber: number[] = [15, 30, 60, 60 * 4, 60 * 12]; // Minuts

  maxScales: string[] = ['Auto', '100', '500', '1000'];
  maxNumberScales: number[] = [0, 100, 500, 1000];

  // Default selected indices
  selectedIntervalIndex: number = 1; // default to '30 minutes' which is at index 1
  selectedMaxScaleIndex: number = 0; // default to 'Auto' which is at index 0

  id: string = '';
  error: boolean = false;
  connected: boolean = false;
  name: string = '';
  hide: boolean = false;

  routType: String = '';
  idLength: Number = 6;

  ngOnInit() {
    this.smartflow.settings.setUpdateTime(this.chartIntervalsNumber[this.selectedIntervalIndex]); // Set defult interval

    // Get the paramters from url, lets try to find id and name
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params !== null && params !== undefined) {
        this.id = params['id'] === undefined ? '' : params['id'];
        this.name = params['name'] === undefined ? '' : params['name'];

        if (this.id !== '' && this.id.length === 6) {
          this.smartflow.name = this.name;
          // This means we have id to connect to.
          // Set connecting high but we will connect when view is init (ngAfterViewInit)
          this.smartflow.connecting = true;

          if (this.smartflow.connected.value) {
            // We are alrady connected. Need to discunnet first
            this.fb.discunnect();
          }

          setTimeout(() => {
            // Start of by disconencting
            this.connect();
          }, 1250);

        } else {
          if (this.smartflow.connected.value) {
            // We are alrady connected but we
            this.name = this.smartflow.name;
          }
        }
      }
    });
  }

  constructor(private fb: FirebaseService, public fs: FirestoreService, private route: ActivatedRoute) {
    super();
  }

  connect() {
    if (!this.smartflow.connected.value) {
      this.smartflow.connecting = true;
      if (this.id.length === 6) {
        this.fb.connect(this.id).then(
          () => {
            this.showMessage('success', 'Connected!', 2000);
            setTimeout(() => {
              // We should have the first data now so we can see if the system
              // is running
              this.getActiveSession();
              this.showMessage('success', 'Downloading data!', 3000);
              setTimeout(() => {
                // We should have the first data now so we can see if the system
                // is running
                this.fs.addConnectionToSmartFlow(this.id);
                this.setConnected();
              }, 2500);
            }, 2000);

          },
          () => {
            this.error = true;
            this.smartflow.connecting = false;
            this.showMessage('danger', 'No SmartFlow with this ID!', 3000);
          }
        );
      }
    } else {
      this.fb.discunnect();
      this.smartflow.connecting = false;
    }
  }

  setConnected() {
    this.fb.linkAllDevices();
    this.connected = true;
    this.smartflow.connecting = false;
    this.smartflow.setConnected(true);
  }

  getActiveSession() {
    this.fs.getSmartFlowInfo(this.id)
      .pipe(take(1))
      .subscribe((info) => {
        if (info !== null && info !== undefined && info.session_on) {
          this.getSession(info.session_id);
        }
      });
  }

  getSession(id: string) {
    this.fs
      .getSession(id)
      .pipe(take(1))
      .subscribe((data) => {
        if (data !== null && data !== undefined) {
          // Data is avalable, but it has counters localtime, not server time or users so we need to set user time
          var t = Helper.generateTimeStamp();
          // We know the time is 15 sec apart, so we can just substract the length of data
          t.seconds = t.seconds - data.length * 15; // TODO later this will be calculated by the time total length and datapoints length
          data.forEach(d => {
            if ('data' in d && 'device' in d.data && typeof d.data.device !== undefined) {
              if (d.data.device === null) {
              } else {
                // Fist add all data to devices. Go through each device, could add a function, to device
                // copy, and just pass in d.data.device
                Object.keys(d.data.device).forEach(dd => {
                  switch (dd) {
                    case 'counter': {
                      //statements;
                      Helper.copy(d.data.device.counter, this.smartflow.counter.data);
                      break;
                    }
                    case 'SmartFlow': {
                      //statements;
                      Helper.copy(d.data.device.smartflow, this.smartflow.smartflow.data);
                      break;
                    }
                    case 'grader': {
                      //statements;
                      Helper.copy(d.data.device.grader, this.smartflow.grader.data);
                      break;
                    }
                    case 'pump': {
                      //statements;
                      Helper.copy(d.data.device.pump, this.smartflow.pump.data);
                      break;
                    }
                    case 'dc': {
                      //statements;
                      Helper.copy(d.data.device.dc, this.smartflow.dc.data);
                      break;
                    }
                  }
                });
              }

              // Now all data has been added to devices, now we can give them all same time stamp and update data points
              // This is doen so all charts have same time stamp.
              // If device is null we just duplicate the point with new time
              d.data.sort.seconds = t.seconds;
              this.smartflow.addDataPoints(d.data.sort);

              t.seconds = t.seconds + 15;
            }
          });
        }
      });
  }

  formatTime(time: string) {
    if (time.includes('/') && time.includes(':') && time.length > 12) {
      return time.substring(8, 10) + '/' + time.substring(5, 7) + '/' + time.substring(0, 4) + " " + time.substring(11);
    }
    return time;
  }

  whatTime(time: string): string {
    return Helper.getTimeCSS(time);
  }

  onIntervalChange() {
    this.smartflow.settings.setUpdateTime(this.chartIntervalsNumber[this.selectedIntervalIndex]);
    this.smartflow.settings.notifyUpdate(false);
  }

  onMaxScaleChange() {
    this.smartflow.settings.updateMaxScale(this.maxNumberScales[this.selectedMaxScaleIndex]);
    this.smartflow.settings.notifyUpdate(true);
  }
} 
