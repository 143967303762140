<div class="d-flex">
    <h2 class="mx-4 mt-2">All SmartFlows</h2>
</div>

<div [hidden]="true" class="top mx-auto p-4" style="max-width: 800px;">
    <mat-accordion class="example-headers-align col-12" multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Add SmartFlow
                </mat-panel-title>
                <mat-panel-description>
                    Tap here to add!
                    <mat-icon>add_box</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form #clientForm="ngForm" (ngSubmit)="onSubmit(clientForm)">
                <div class="form-group">
                    <label class="fw-bold mb-1 text-secondary">SmartFlow ID</label>
                    <input class="form-control" name="id" #smartflowID="ngModel"
                        [ngClass]="{'is-invalid':smartflowID.errors && smartflowID.touched}" [(ngModel)]="sfID"
                        minlength="6" maxlength="6" oninput="this.value = this.value.toUpperCase()" required>
                </div>

                <div class="form-group">
                    <label class="fw-bold my-1 text-secondary">Name</label>
                    <input type="text" class="form-control" name="name" #addedName="ngModel"
                        [ngClass]="{'is-invalid':addedName.errors && addedName.touched}" [(ngModel)]="sfName"
                        minlength="2" required>
                </div>

                <input type="submit" value="Add" class="btn btn-primary col-12 mt-3">
            </form>
            <div *ngIf="message" class="alert mt-4" [ngClass]="message_type" role="alert">
                {{errorMessage}}
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<mat-divider [hidden]="true"></mat-divider>

<div class="top mx-auto pt-3" style="max-width: 800px;">
    <div class="form-inline d-flex justify-content-center col-12">
        <mat-icon aria-hidden="false" aria-label="Example home icon" class="mt-1">search</mat-icon>
        <input class="form-control form-control-sm ml-3 w-75 mx-2" type="text" id="search_smartFlow"
            placeholder="Search" aria-label="Search" (input)="onKey($event)">
    </div>
</div>
<div class="top mx-auto pt-2" style="max-width: 800px;">
    <div class="form-inline d-flex justify-content-center col-12">
        <input class="form-check-input mx-2" type="checkbox" [(ngModel)]="this.settings.settings.allSmartFlows.online"
            (change)="showResults()">
        <h5 class="form-check-label mx-2">Online</h5>
        <input class="form-check-input mx-2" type="checkbox" [(ngModel)]="this.settings.settings.allSmartFlows.on"
            (change)="showResults()">
        <h5 class="form-check-label mx-2">On</h5>
        <input class="form-check-input mx-2" type="checkbox" [(ngModel)]="this.settings.settings.allSmartFlows.graders"
            (change)="showResults()">
        <h5 class="form-check-label mx-2">Graders</h5>
        <input class="form-check-input mx-2" type="checkbox" [(ngModel)]="this.settings.settings.allSmartFlows.pumps"
            (change)="showResults()">
        <h5 class="form-check-label mx-2">Pumps</h5>
        <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked (change)="sortAZ()">
            <label class="form-check-label" for="flexRadioDefault1">
              A-Z
            </label>
          </div>
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (change)="sort10()">
            <label class="form-check-label" for="flexRadioDefault2">
              1-0
            </label>
        </div>
    </div>
</div>

<div class="top mx-auto pt-2" style="max-width: 800px;">
    <div class="form-inline d-flex justify-content-center col-12">
        <button (click)="last()" class="btn btn-outline-secondary mx-2" [disabled]="step == 0">Last</button>
        <div class="form-inline d-flex align-items-center mx-3">
            <h6>Showing {{step * step_size + 1}} - {{(step + 1) * step_size}}  of  {{sfForList.size()}} </h6>
        </div>
        <button (click)="next()" class="btn btn-outline-secondary mx-2" [disabled]="((step + 1)  * step_size) >= sfForList.size()">Next</button>
    </div>
</div>

<div class="row content mx-auto" style="max-width: 800px;">
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [expanded]="true" class="example-headers-align"
            *ngFor="let smartflow of sfForList.all() | slice:(step * step_size ):((step + 1) * step_size) ; index as i; trackBy:track">
            <mat-expansion-panel-header *ngIf="!smartflow.hide" class="d-flex justify-content-between">
                <mat-panel-title>
                    <small>#{{step * step_size + (i + 1)}} : </small><small
                        [ngClass]="(whatTime(smartflow.updateTime))">{{smartflow.updateTime}}</small>
                </mat-panel-title>
                <mat-panel-description>
                    <h6></h6>
                    <p class="mb-1">
                        <large [ngClass]="(smartflow.session_on? 'text-success' : 'text-danger')">
                            {{smartflow.session_on ?'ON':'OFF'}}</large>
                    </p>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!smartflow.hide">
                <h6>{{smartflow.info.id}}</h6>

                <p class="mb-1">Devices :  <span *ngFor="let dev of smartflow.devices; index as j;"
                        class="badge alert-success mx-1">{{j==0?'Counter : ':''}} {{dev.id}}</span>
                </p>
                <div class="d-flex justify-content-between">
                    <p class="mb-1">Version : {{smartflow.version}} </p>
                    <p class="mb-1">Total connections : {{smartflow.count}} </p>
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <p><span class="text-secondary"> Login : {{getTimeStr(smartflow.update)}}</span></p>
                    <p><span class="text-secondary">{{(smartflow.created !== undefined )? "Created : " + getTimeStr(smartflow.created) :""}}</span></p>
                </div>
                <div class="d-flex justify-content-between">
                    <button (click)="connectTo(smartflow.info.id,smartflow.info.name)" class="btn btn-outline-success"
                        routerLink=""
                        [queryParams]="{ id:smartflow.info.id , name:smartflow.info.name}">Connect</button>
                    <button [hidden]="true" class="btn btn-outline-primary" [routerLink]="['/smartflows/smartflow']"
                        [queryParams]="{ id:smartflow.info.id , name:smartflow.info.name}">Info</button>
                    <button [hidden]="true" class="btn btn-outline-danger"
                        (click)="openDialog(smartflow.info.docID, smartflow.info.id, smartflow.info.name)">Remove</button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>