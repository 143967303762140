import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
import { MyMessage } from 'src/app/classes/my-message';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';


@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent extends MyMessage implements OnInit {
  downloading: boolean = false;
  deleting: boolean = false;

  step: number = 0;
  step_size: number = 50;

  constructor(public fs: FirestoreService,
    public global_data: GlobalDataService,
    public dialog: MatDialog,
    private fns: AngularFireFunctions,
  ) {
    super();
  }

  firebaseFunctionSessionDelete(id: string): Observable<any> {
    const foc = this.fns.httpsCallable('sessionDelete');
    return foc({ id: id });
  }

  ngOnInit(): void {

  }

  getSessions() {
    this.downloading = true;
    // Clear array
    this.global_data.sessions.splice(0, this.global_data.sessions.length);

    this.fs
      .getSessions(this.global_data.session.selected.id)
      .pipe(take(1))
      .subscribe((data) => {
        if (data !== null && data !== undefined) {
          this.showMessage('success', 'Download complete.', 3000);
          data.sort((a, b) => this.getTimeSec(a.data.end) > this.getTimeSec(b.data.end) ? -1 : 1);

          this.global_data.session.loaded = true;

          data.forEach((val) => {
            // Later we can set this in settings to be bigger
            if (val.data.total > 100) {
              this.global_data.sessions.push(Object.assign({}, val))
            }
          });
        } else {
          this.showMessage('danger', 'Error downloading.', 3000);
        }
        this.downloading = false;
      });
  }

  getTimeSec(time: any) {
    if (time !== undefined && time !== null && time !== '') {
      return time.seconds;
    }

    return 0;
  }

  openDialog(session: any) {
    const dialogRef = this.dialog.open(DialogSessionDelete, {
      data: { id: session.id, smartlfow_id: session.data.owner },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleting = true;
        this.firebaseFunctionSessionDelete(session.id).subscribe((value: any) => {
          console.log(value)

          if (value !== null && value !== undefined) {
            const index = this.global_data.sessions.indexOf(session, 0);
            console.log(session.id, " index of ", index)
            if (index > -1) {
              this.global_data.sessions.splice(index, 1);
            }
            this.deleting = false;
            this.showMessage('success', 'Data deleted.', 3000);
          } else {
            this.showMessage('error', 'Failed to delete.', 3000);
          }
        })
      }
    });
  }

  getTimeStr(time: any) {
    if (time !== undefined && time !== null && time !== '') {
      let temp = new Date(time.seconds * 1000).toISOString()
      return temp.substring(11, 19);
    }

    return '';
  }

  getDataStr(time: any) {
    if (time !== undefined && time !== null && time !== '') {
      let temp = new Date(time.seconds * 1000).toISOString()
      return temp.substring(8, 10) + '/' + temp.substring(5, 7) + '/' + temp.substring(0, 4);
    }

    return '';
  }

  duration(t1: any, t2: any) {
    if (t1 !== undefined && t1 !== null && t1 !== '') {
      if (t2 !== undefined && t2 !== null && t2 !== '') {
        let timeDiff = t1.seconds - t2.seconds; //in ms
        // get seconds 
        let ss = Math.round(timeDiff);
        let dataStr = new Date(ss * 1000).toISOString().substring(11, 19)
        return dataStr.substring(0, 2) + "h " + dataStr.substring(3, 5) + "m " + dataStr.substring(6, 8) + "s";
      }
    }
    return '';
  }

  changeClient(id: any) {
    this.global_data.session.selected = id;
    this.global_data.session.loaded = false;
  }

  last() {
    if (this.step > 0) {
      this.step--;
    }
  }

  next() {
    if ((this.step * this.step_size + 1) < this.global_data.sessions.length) {
      this.step++;
    }
  }

}

@Component({
  selector: 'dialog-session-delete',
  templateUrl: 'dialog-session-delete.html',
  styleUrls: ['sessions.component.css'],
})
export class DialogSessionDelete {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}