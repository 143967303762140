import { Component, Input } from '@angular/core';
import { MyMessage } from 'src/app/classes/my-message';
import { SmartFlow } from 'src/app/classes/smartflow';

@Component({
  selector: 'app-session-smartflow',
  templateUrl: './session-smartflow.component.html',
  styleUrls: ['./session-smartflow.component.css']
})
export class SessionSmartflowComponent extends MyMessage {
  @Input()
  smartflow: SmartFlow = new SmartFlow();
  @Input()
  id: string = '';

  constructor() {
    super();
  }

  formatTime(time: string) {
    if (time.includes('/') && time.includes(':') && time.length > 12) {
      return time.substring(8, 10) + '/' + time.substring(5, 7) + '/' + time.substring(0, 4) + " " + time.substring(11);
    }
    return time;
  }
}
