import { Helper } from '../util/hleper';

interface SmartFlow {
  name: string;
  online: string;
  runTime: string;
  running: boolean;
  sp1: string;
  sp2: string;
  sp3: string;
  sp4: string;
}

export interface Session {
  on:boolean;
}

export class SmartFlowClass {
  data: SmartFlow = {
    name: '',
    online: '',
    runTime: '',
    running: false,
    sp1: '',
    sp2: '',
    sp3: '',
    sp4: '',
  };

  defult: SmartFlow = {
    name: '',
    online: '',
    runTime: '',
    running: false,
    sp1: '',
    sp2: '',
    sp3: '',
    sp4: '',
  };

  clear() {
    Helper.copy(this.defult, this.data);
  }
}
